export function formatDate(originalDate: string | undefined) {
  if (originalDate === undefined) {
    return "";
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [year, month, day] = originalDate.split("-").map(Number);
  const monthName = months[month - 1];

  return `${monthName} ${day}, ${year}`;
} //end of formatDate

export function formatDateOnlyYear(originalDate: string | undefined) {
  if (originalDate === undefined) {
    return "";
  }

  const year = originalDate.split("-")[0];
  return year;
}

export function formatDateNoYear(originalDate: string | undefined) {
  if (originalDate === undefined) {
    return "";
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [year, month, day] = originalDate.split("-").map(Number);
  const monthName = months[month - 1];

  return `${monthName} ${day}`;
}

export function formatTime(originalTime: string | undefined) {
  if (originalTime === undefined) {
    return "";
  }

  const [hours, minutes] = originalTime.split(":").map(Number);

  const period = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;

  return `${formattedHours}:${String(minutes).padStart(2, "0")} ${period}`;
} //end of formatTime
