import updateDate from "../../../assets/database/legal_update_date.json";
import contactInfo from "../../../assets/database/contact_info.json";

const Page = () => {
  return (
    <main className="main-page" id="legal-page">
      <div className="heading-group">
        <h1 className="legal-heading">Terms and Conditions</h1>
        <span>
          &#40;Last updated on {updateDate.termsAndConditionsUpdateDate}&#41;
        </span>
      </div>

      <div className="body-group">
        <div className="body-element">
          <h2 className="body-element-heading">Acceptance of Terms</h2>
          <p className="body-element-body">
            By using the Pixely website ("we," "us," or "our"), you agree to
            comply with and be bound by these Terms and Conditions. If you do
            not agree to these terms, please refrain from using our services.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Description of Service</h2>
          <p className="body-element-body">
            Pixely is a platform dedicated to the creation of personalized RSVP
            websites. These RSVP website templates are designed and owned by
            Pixely.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Payment</h2>
          <p className="body-element-body">
            To purchase a service, users must follow the ordering process
            provided on our website.
          </p>
          <p className="body-element-body">
            Payment is due at the time of purchase, and purchased service will
            be granted upon successful payment.
          </p>
        </div>

        {/* 
        <div className="body-element">
          <h2 className="body-element-heading">Edit and Delete and Refund</h2>
          <p className="body-element-body">
            Editing and deletion of the online memorial can be requested using
            our contact form on <a href={`/contact`}>Contact Page</a>.
          </p>
          <p className="body-element-body">
            Due to the digital nature of our service, we generally do not offer
            refunds. Refunds may be considered in exceptional cases, and the
            decision rests with Pixely.
          </p>
        </div> */}

        {/* <div className="body-element">
          <h2 className="body-element-heading">Ownership and Licensing</h2>
          <p className="body-element-body">
            You obtain full ownership of the photos you used for the online
            memorial. We do not have any ownership on your photo. We will not
            use or distribute any user's photo or information.
          </p>
          <p className="body-element-body">
            However, if your photo or content violate our policy, we have right
            to delete your online memorial anytime without notice.
          </p>
        </div> */}

        <div className="body-element">
          <h2 className="body-element-heading">User Responsibilities</h2>
          <li className="body-element-body">
            Users are responsible for the appropriate and lawful use of our
            service.
          </li>
          <li className="body-element-body">
            Users must refrain from uploading images that are offensive,
            harmful, or violate community standards.
          </li>
          <li className="body-element-body">
            Users are prohibited from uploading or sharing illegal content,
            including but not limited to explicit or adult content, content that
            promotes illegal activities, or any content that may be deemed
            inappropriate.
          </li>

          <li className="body-element-body">
            Users must not upload, share, or distribute images that exploit or
            endanger children. This includes, but is not limited to, child abuse
            imagery, child endangerment, or any violation of child protection
            laws.
          </li>

          <li className="body-element-body">
            Users should respect the intellectual property rights of others.
          </li>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">
            Governing Law and Jurisdiction
          </h2>
          <p className="body-element-body">
            These Terms and Conditions are governed by and construed in
            accordance with the laws of Canada. Any disputes arising under or in
            connection with these terms shall be subject to the exclusive
            jurisdiction of the courts in Canada.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">
            Changes to Terms and Conditions
          </h2>
          <p className="body-element-body">
            We may update or modify these Terms and Conditions from time to time
            without prior notice. Any changes will be posted on this page with
            an updated effective date.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Contact Us</h2>
          <p className="body-element-body">
            If you have any questions, concerns, or requests regarding this
            Terms and Conditions, please contact Pixely at&nbsp;
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>.
          </p>
        </div>
      </div>

      <span
        className="back-to-top-button"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        Back to top
      </span>
    </main>
  );
};

export default Page;
