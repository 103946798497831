import { useState, useEffect, useRef, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
import { useUserProfileContext } from "../../../contexts/userProfileContext";
// visuals
import { ReactComponent as MoreIcon } from "../../../assets/visuals/icons/dashboard/more_icon.svg";
import { ReactComponent as OpenInNewIcon } from "../../../assets/visuals/icons/dashboard/open_in_new_icon.svg";
import { ReactComponent as XIcon } from "../../../assets/visuals/icons/x_icon.svg";
// visual-drop menu
import { ReactComponent as ManageIcon } from "../../../assets/visuals/icons/dashboard/manage_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/visuals/icons/dashboard/delete_icon.svg";
import { ReactComponent as ShareIcon } from "../../../assets/visuals/icons/dashboard/share_icon.svg";
// data
import SystemData from "../../../assets/database/general_system_data.json";
// types
import { PublicationDataType } from "../../../types/Types";

const Dashboard = () => {
  // variables
  const navigate = useNavigate();
  const [publications, setPublications] = useState<PublicationDataType[]>();
  const { userProfile, setUserProfile } = useUserProfileContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [rerender, forceRerender] = useReducer((x) => x + 1, 0);

  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );
  const dropdownRefs = useRef<any[]>([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [publicationToDelete, setPublicationToDelete] = useState<string | null>(
    null
  );

  const deleteConfirmInputRef = useRef<HTMLInputElement>(null);

  // functions
  function formatCreatedDate(rawDate: string) {
    const date = new Date(rawDate);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function handleDeleteClick(publication_url: string) {
    setPublicationToDelete(publication_url);
    setIsDeleteModalOpen(true);
  }

  const handleDeleteConfirm = async () => {
    // check if the input is matching with publication_url
    // input empty

    if (deleteConfirmInputRef.current?.value === "") {
      deleteConfirmInputRef.current?.setCustomValidity(
        "Please fill in this field."
      );

      deleteConfirmInputRef.current?.reportValidity();

      deleteConfirmInputRef.current.value = "";
      return;
    }

    if (deleteConfirmInputRef.current?.value !== publicationToDelete) {
      deleteConfirmInputRef.current?.setCustomValidity(
        "The publication address you entered does not match."
      );

      deleteConfirmInputRef.current?.reportValidity();
    } else {
      try {
        if (deleteConfirmInputRef.current) {
          deleteConfirmInputRef.current.value = "";
        }

        await axios
          .delete(
            `${WebsiteURL().serverURL()}/api/publications/delete-publication/${publicationToDelete}`,
            {
              withCredentials: true,
            }
          )
          .then(() => {
            setPublications((prevPublications) =>
              prevPublications?.filter(
                (publication) =>
                  publication?.publication_url !== publicationToDelete
              )
            );
          });
      } catch (error) {
        console.error("Error deleting publication:", error);
      } finally {
        setPublicationToDelete(null);
        setIsDeleteModalOpen(false);
      }
    }
  };

  const handleDropdownPosition = (index: number) => {
    const buttonElement = dropdownRefs.current[index];
    const dropdownElement = buttonElement.querySelector(
      ".more-button-dropdown"
    ) as HTMLElement;
    const rect = buttonElement.getBoundingClientRect();

    // Check right space
    if (window.innerWidth - rect.right < 180) {
      dropdownElement.style.left = "auto";
      dropdownElement.style.right = "0";
    } else {
      dropdownElement.style.left = "0";
      dropdownElement.style.right = "auto";
    }

    // Check bottom space
    if (window.innerHeight - rect.bottom < 120) {
      dropdownElement.style.top = "auto";
      dropdownElement.style.bottom = "calc(100% + 3px)";
    } else {
      dropdownElement.style.top = "calc(100% + 3px)";
      dropdownElement.style.bottom = "auto";
    }
  };

  // useEffect
  useEffect(() => {
    // login check //
    async function fetchPublications(userData: any) {
      setIsLoading(true);

      try {
        const response = await axios.get(
          `${WebsiteURL().serverURL()}/api/publications/published-websites/${
            userData.user_id
          }`,
          {
            withCredentials: true,
          }
        );

        setPublications(response.data);
      } catch (error) {
        console.error("Error fetching published announcements:", error);
      } finally {
        setIsLoading(false);
      }
    }

    const getUserDataAndFetch = async () => {
      if (userProfile) {
        fetchPublications(userProfile);
      } else {
        try {
          const { data } = await axios.get(
            `${WebsiteURL().serverURL()}/auth/profile`,
            {
              withCredentials: true,
            }
          );

          if (data.user) {
            setUserProfile(data.user);

            fetchPublications(data.user);
          } else {
            // navigate("/main/login");
          }
        } catch (error: any) {
          if (error.response) {
            if (error.response.status === 404) {
              console.error("No data found.");
            } else {
              console.error("Server error:", error);
            }
          } else {
            console.error("Network error:", error);
          }
          navigate("/main/login");
        }
      }
    };

    getUserDataAndFetch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // drop down cancel //
    function handleClickOutside(event: MouseEvent) {
      if (
        openDropdownIndex !== null &&
        dropdownRefs.current[openDropdownIndex] &&
        !dropdownRefs.current[openDropdownIndex].contains(event.target)
      ) {
        setOpenDropdownIndex(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownIndex]);

  useEffect(() => {
    // delete modal //
    if (isDeleteModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    if (isDeleteModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isDeleteModalOpen]);

  return (
    <main className="main-page" id="dashboard-page">
      {publications ? (
        <>
          <div className="publication-container">
            {publications.map((publication, index) => {
              return (
                <div className="publication-element" key={index}>
                  {/* title */}
                  <div className="publication-title-wrapper">
                    <Link
                      to={`./${publication?.publication_url}`}
                      className="publication-title"
                    >
                      {publication?.event_name
                        ? publication.event_name
                        : publication?.publication_url}
                    </Link>
                  </div>

                  {/* url */}
                  <Link
                    className="publication-link-container"
                    to={`${WebsiteURL().clientURL()}/${
                      publication?.publication_url
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      https://www.pixely.ca/{publication?.publication_url}
                    </span>

                    <OpenInNewIcon />
                  </Link>

                  {/* date */}
                  <span className="publication-published-date">
                    Published date: {formatCreatedDate(publication?.createdAt!)}
                  </span>

                  {/* more button */}
                  <div
                    className={`more-button ${
                      openDropdownIndex === index && "open"
                    }`}
                    ref={(el) => (dropdownRefs.current[index] = el)}
                  >
                    <MoreIcon
                      onClick={() => {
                        if (openDropdownIndex === index) {
                          setOpenDropdownIndex(null);
                        } else {
                          setOpenDropdownIndex(index);
                          setTimeout(() => handleDropdownPosition(index), 0);
                        }
                      }}
                    />

                    {openDropdownIndex === index && (
                      <div className="more-button-dropdown">
                        <Link
                          to={`./${publication?.publication_url}`}
                          className="more-button-dropdown-element"
                          id="manage-button"
                        >
                          <ManageIcon />
                          <span>Manage</span>
                        </Link>

                        <div
                          className="more-button-dropdown-element"
                          id="share-button"
                          onClick={() => {
                            navigator.share({
                              title: `${
                                publication?.event_name
                                  ? publication.event_name
                                  : "Pixely RSVP Website"
                              }`,
                              text: `${publication?.invitation_message}`,
                              url: `${WebsiteURL().clientURL()}/${
                                publication?.publication_url
                              }`,
                            });
                          }}
                        >
                          <ShareIcon />
                          <span>Share</span>
                        </div>

                        <div
                          className="more-button-dropdown-element"
                          id="delete-button"
                          onClick={() => {
                            setOpenDropdownIndex(null);
                            handleDeleteClick(publication?.publication_url!);
                          }}
                        >
                          <DeleteIcon />
                          <span>Delete</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="button-container">
            <Link className="button" to={`${SystemData.navigation_url[1]}`}>
              <span>Create RSVP website</span>
            </Link>
          </div>

          {/* /////////////////////////////////////////////////// */}
          {/* confirm modal */}
          <div className={`modal-overlay ${isDeleteModalOpen}`}>
            <div className="modal">
              <XIcon
                className="modal-x-button tribute-modal-x-button"
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  if (deleteConfirmInputRef.current) {
                    deleteConfirmInputRef.current.value = "";
                  }
                }}
              />

              <div className="modal-heading-container">
                <DeleteIcon className="modal-heading-icon" />

                <h3 className="modal-heading">Delete Death Announcement</h3>
              </div>

              <div className="modal-body-container">
                <span className="modal-span">
                  Are you sure want to delete this death announcement?
                </span>

                <span className="modal-span">
                  This action is not reversible. Please be certain.
                </span>
              </div>

              <div className="modal-input-container">
                <span className="modal-span ">
                  Type the URL address{" "}
                  <span id="publication-url-span">
                    {`${publicationToDelete}`}
                  </span>{" "}
                  to delete:
                </span>

                <input
                  className="modal-input"
                  ref={deleteConfirmInputRef}
                  onChange={() => {
                    deleteConfirmInputRef.current?.setCustomValidity("");
                  }}
                ></input>
              </div>

              <div className="modal-button-container">
                <div
                  className="modal-button gray"
                  onClick={() => {
                    setIsDeleteModalOpen(false);

                    if (deleteConfirmInputRef.current) {
                      deleteConfirmInputRef.current.value = "";
                    }
                  }}
                >
                  Cancel
                </div>
                <div
                  className="modal-button red"
                  onClick={() => {
                    handleDeleteConfirm();
                  }}
                >
                  Delete
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="only-button-div">
          <div className="button-container">
            <div className={`loading-span ${isLoading}`}>Loading...</div>

            <Link to={`${SystemData.navigation_url[1]}`} className="button">
              <span>Create First RSVP website</span>
            </Link>
          </div>
        </div>
      )}
    </main>
  );
};

export default Dashboard;
