import { Link } from "react-router-dom";
import { ReactComponent as FullLogoBlackBlack } from "../../../assets/visuals/pixely-logos/full_logo_black_black.svg";
// data
import SystemData from "../../../assets/database/general_system_data.json";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="main-footer">
      <div className="footer-legal">
        <Link to="/" className="footer-logo-anchor">
          <FullLogoBlackBlack />
        </Link>

        <div className="footer-legal-group">
          <div className="footer-anchor-wrapper">
            <Link className="footer-legal-link" to="/main/privacy-policy">
              Privacy Policy
            </Link>
          </div>

          <span className="footer-legal-text" id="divider">
            |
          </span>

          <div className="footer-anchor-wrapper">
            <Link className="footer-legal-link" to="/main/terms-and-conditions">
              Terms and Conditions
            </Link>
          </div>

          <span className="footer-legal-text" id="divider">
            |
          </span>

          <span className="footer-legal-text">
            <span>© {currentYear} Pixely.&nbsp;</span>
            <span>All rights reserved.</span>
          </span>
        </div>
      </div>

      <nav className="footer-nav">
        <div className="footer-nav-group">
          <span className="footer-nav-heading">Sitemap</span>

          <div className="footer-nav-link-container">
            <Link
              className="footer-nav-link"
              to={`${SystemData.navigation_url[1]}`}
            >
              Templates
            </Link>
          </div>
        </div>

        <div className="footer-nav-group">
          <span className="footer-nav-heading">Help</span>

          <div className="footer-nav-link-container">
            <Link className="footer-nav-link" to="/main/contact">
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
