import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// data
import WebsiteURL from "../../../assets/database/WebsiteURL";
// visuals
import { ReactComponent as RoundShareIcon } from "../../template-visuals/template-icons/round-icons/round_share_icon.svg";
// logos
import { ReactComponent as FullLogoBlackBlack } from "../../../assets/visuals/pixely-logos/full_logo_black_black.svg";
import { ReactComponent as FullLogoBlackWhite } from "../../../assets/visuals/pixely-logos/full_logo_black_white.svg";
import { ReactComponent as FullLogoWhiteBlack } from "../../../assets/visuals/pixely-logos/full_logo_white_black.svg";
import { ReactComponent as FullLogoWhiteWhite } from "../../../assets/visuals/pixely-logos/full_logo_white_white.svg";

type TemplateFooterType = {
  logo: string;
};

const TemplateFooter = (props: TemplateFooterType) => {
  // variables
  const [logoColor, setLogoColor] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");

  // functions

  // useEffects
  useEffect(() => {
    const [logo_color, text_color] = props.logo.split("_");
    setLogoColor(logo_color);
    setTextColor(text_color);
  }, [props.logo]);

  return (
    <footer className="template-footer">
      <Link to={`${WebsiteURL().clientURL()}`} target="_blank" rel="noreferrer">
        {logoColor === "black" && textColor === "black" ? (
          <FullLogoBlackBlack />
        ) : logoColor === "black" && textColor === "white" ? (
          <FullLogoBlackWhite />
        ) : logoColor === "white" && textColor === "black" ? (
          <FullLogoWhiteBlack />
        ) : (
          <FullLogoWhiteWhite />
        )}
      </Link>
    </footer>
  );
};

export default TemplateFooter;
