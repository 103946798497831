import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
// data
import ServerURL from "../../assets/database/WebsiteURL";
// visuals
import LoadingGif from "../../assets/visuals/GIFs/rsvp_loading.gif";
// types
import { PublicationDataType } from "../../types/Types";
// templates
import T1 from "../../templates/template-components/T1/T1";
import T2 from "../../templates/template-components/T2/T2";
import T3 from "../../templates/template-components/T3/T3";
import T4 from "../../templates/template-components/T4/T4";
import T5 from "../../templates/template-components/T5/T5";
// import T6 from "../../templates/template-components/T6/T6";

function Memorial() {
  // variables
  const { publication_url } = useParams();
  const [publicationData, setPublicationData] = useState<PublicationDataType>();
  const [isLoadingDone, setIsLoadingDone] = useState<boolean>(false);

  // userEffect
  useEffect(() => {
    const getMemorialData = async () => {
      try {
        const response = await axios.get(
          `${ServerURL().serverURL()}/api/publications/${publication_url}`,
          {
            withCredentials: true,
          }
        );

        //dev
        setPublicationData(response.data);
        setIsLoadingDone(true);
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 404) {
            // No matching data
            console.error("No data found.");
          } else {
            // Other server error
            console.error("Server error:", error);
          }
        } else {
          // Network error or similar
          console.error("Network error:", error);
        }
        setIsLoadingDone(true);
      }
    };

    getMemorialData();
  }, [publication_url]);

  return (
    <div id="live-website-page">
      {isLoadingDone ? (
        publicationData ? (
          (function selectTemplate() {
            switch (publicationData?.template_id) {
              case "t1":
                return <T1 publicationData={publicationData} />;
              case "t2":
                return <T2 publicationData={publicationData} />;
              case "t3":
                return <T3 publicationData={publicationData} />;
              case "t4":
                return <T4 publicationData={publicationData} />;
              case "t5":
                return <T5 publicationData={publicationData} />;
              // case "t6":
              //   return <T6 publicationData={publicationData} />;

              default:
                return <>Unknown template</>;
            }
          })()
        ) : (
          <div>
            <span id="live-website-not-found-span">RSVP Website Not Found</span>

            <Link className="button" to="/">
              <span>Back to Home</span>
            </Link>
          </div>
        )
      ) : (
        <img id="live-website-loading-gif" src={LoadingGif} alt="loading gif" />
      )}
    </div>
  );
}

export default Memorial;
