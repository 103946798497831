import { Link } from "react-router-dom";
import WebsiteURL from "../../../assets/database/WebsiteURL";
// visuals
import { ReactComponent as GoogleLogo } from "../../../assets/visuals/login-logos/google_logo.svg";
import { ReactComponent as FacebookLogo } from "../../../assets/visuals/login-logos/facebook_logo.svg";

const Login = () => {
  return (
    <main className="main-page" id="login-page">
      <div className="login-container">
        <h1 className="login-heading">Login to Pixely</h1>

        <div className="oauth-login-container">
          <Link
            className="oauth-link"
            id="google-oauth-link"
            to={`${WebsiteURL().serverURL()}/auth/google`}
          >
            <div className="logo-wrapper">
              <GoogleLogo />
            </div>

            <span className="oauth-span">Continue with Google</span>
            <span className="oauth-span short">Login with Google</span>
          </Link>

          <Link
            className="oauth-link"
            id="facebook-oauth-link"
            to={`${WebsiteURL().serverURL()}/auth/facebook`}
          >
            <div className="logo-wrapper">
              <FacebookLogo />
            </div>

            <span className="oauth-span">Continue with Facebook</span>
            <span className="oauth-span short">Login with Facebook</span>
          </Link>
        </div>
      </div>

      <span className="login-agreement-span">
        By signing up, you acknowledge that you have read, understood, and
        agreed to Pixely’s&nbsp;
        <Link to="/main/terms-and-conditions">terms</Link>
        &nbsp;and&nbsp;
        <Link to="/main/privacy-policy">privacy policy</Link>.
      </span>
    </main>
  );
};

export default Login;
