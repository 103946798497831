import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
import { useUserProfileContext } from "../../../contexts/userProfileContext";
// data
import SystemData from "../../../assets/database/general_system_data.json";
// logos
import { ReactComponent as FullLogoBlackBlack } from "../../../assets/visuals/pixely-logos/full_logo_black_black.svg";
// icons
import { ReactComponent as HomeIcon } from "../../../assets/visuals/icons/header/nav_home_icon.svg";
import { ReactComponent as HelpIcon } from "../../../assets/visuals/icons/header/nav_help_icon.svg";
import { ReactComponent as CircleUserIcon } from "../../../assets/visuals/icons/header/user_account_icon.svg";
import { ReactComponent as BareUserIcon } from "../../../assets/visuals/icons/header/user_account_icon.svg";
import { ReactComponent as MenuIcon } from "../../../assets/visuals/icons/header/menu_icon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/visuals/icons/header/user_logout_icon.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/visuals/icons/header/user_attributes_icon.svg";
import { ReactComponent as DropDownIcon } from "../../../assets/visuals/icons/header/drop_down_arrow_icon.svg";
import { ReactComponent as TemplateIcon } from "../../../assets/visuals/icons/header/nav_template_icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/visuals/icons/x_icon.svg";

const Header = () => {
  // variables
  const navRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const {
    userProfile,
    // triggerAppRerender,
    appRerender,
  } = useUserProfileContext();

  const mobileNavOverlyRef = useRef<HTMLDivElement>(null);

  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  // functions
  const handleLogout = async () => {
    navigate(0);
    try {
      await axios({
        method: "POST",
        url: `${WebsiteURL().serverURL()}/auth/logout`,
        withCredentials: true,
      });
    } catch (error) {
      console.error("something went wrong!", error);
    }
  };

  function handleScrollToggle(e: any) {
    if (e.target.checked) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

  function handleNavClick() {
    navRef.current!.checked = false;
    document.body.style.overflow = "auto";
  }

  // touch functions
  const handleTouchStart = (e: TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart !== null && touchEnd !== null) {
      const swipeDistance = touchEnd - touchStart;
      if (swipeDistance > 50 && window.innerWidth <= 1024) {
        // swipe right to close
        navRef.current!.checked = false;
        document.body.style.overflow = "auto";
      }
    }
    setTouchStart(null);
    setTouchEnd(null);
  };

  // useEffect
  useEffect(() => {
    const mobileOverlay = mobileNavOverlyRef.current;

    if (mobileOverlay) {
      mobileOverlay.addEventListener("touchstart", handleTouchStart);
      mobileOverlay.addEventListener("touchmove", handleTouchMove);
      mobileOverlay.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (mobileOverlay) {
        mobileOverlay.removeEventListener("touchstart", handleTouchStart);
        mobileOverlay.removeEventListener("touchmove", handleTouchMove);
        mobileOverlay.removeEventListener("touchend", handleTouchEnd);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchStart, touchEnd]);

  useEffect(() => {}, [appRerender]);

  return (
    <header id="main-header">
      {/***** Logo *****/}
      <div className="header-logo">
        <Link to="/">
          <FullLogoBlackBlack />
        </Link>
      </div>

      {/***** Nav *****/}
      <nav onTouchStart={() => {}}>
        <input
          className="nav-input"
          id="nav-input"
          type="checkbox"
          ref={navRef}
          onChange={handleScrollToggle}
        ></input>
        <label className="nav-label" htmlFor="nav-input">
          <MenuIcon />
          <CloseIcon />
        </label>

        <div
          className="nav-mobile-overlay"
          onClick={() => {
            navRef.current!.checked = false;
          }}
        ></div>

        <div className="nav-item-container" ref={mobileNavOverlyRef}>
          {/* To Home Page (first) */}
          <Link
            className="nav-item"
            id="nav-home"
            to="/"
            onClick={handleNavClick}
          >
            <HomeIcon />
            <span>Home</span>
          </Link>

          {/* To Memorials Page */}
          <Link
            className="nav-item"
            id="nav-memorials"
            to={`${SystemData.navigation_url[1]}`}
            onClick={handleNavClick}
          >
            <TemplateIcon />
            <span>Templates</span>
          </Link>

          {/* To Contact Page */}
          <Link
            className="nav-item"
            id="nav-contact"
            to="/main/contact"
            onClick={handleNavClick}
          >
            <HelpIcon />
            <span>Contact</span>
          </Link>

          {/* To Login Page */}
          {userProfile ? (
            <div className="nav-item" id="nav-account">
              <div id="account-wrapper">
                <BareUserIcon />
                <span>Account</span>
                <DropDownIcon />
              </div>

              <div className="dropdown-container">
                <div className="dropdown-wrapper">
                  <Link
                    className="dropdown-element"
                    to="/main/dashboard"
                    onClick={handleNavClick}
                  >
                    <DashboardIcon />
                    <span>Dashboard</span>
                  </Link>

                  <div
                    className="dropdown-element"
                    onClick={() => {
                      handleNavClick();
                      // logout
                      handleLogout();
                    }}
                  >
                    <LogoutIcon />
                    <span>Logout</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Link
              className="nav-item"
              id="nav-login"
              to="/main/login"
              onClick={handleNavClick}
            >
              <CircleUserIcon />
              <span>Login</span>
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
