import {
  ReactNode,
  useState,
  useReducer,
  useContext,
  createContext,
} from "react";

// Create the context with an initial value
const UserProfileContext = createContext<any | undefined>(undefined);

type userProfileType = {
  user_id: string;
  user_display_name: string;
  user_picture?: string;
};

// Optional: Create a custom hook for consuming the context
export const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);

  if (!context) {
    throw new Error(
      "useUserProfileContext must be used within a UserProfileContextProvider"
    );
  }
  return context;
};

// Create a component that will provide the context
interface UserProfileContextProviderProps {
  children: ReactNode;
}

export const UserProfileContextProvider = ({
  children,
}: UserProfileContextProviderProps) => {
  // variables
  const [userProfile, setUserProfile] = useState<userProfileType>();
  const [appRerender, triggerAppRerender] = useReducer((x) => x + 1, 0);

  const contextValue: any = {
    userProfile,
    setUserProfile,

    appRerender,
    triggerAppRerender,
  };

  return (
    <UserProfileContext.Provider value={contextValue}>
      {children}
    </UserProfileContext.Provider>
  );
};
