import { useState, useRef, useEffect } from "react";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
import { Helmet } from "react-helmet-async";
// visuals
import { ReactComponent as BackArrow } from "./T2-assets/back_arrow_icon.svg";
import StickMask from "./T2-assets/stick_mask.webp";
import Firecracker from "./T2-assets/firecracker.webp";
// elements
import TemplateFooter from "../../template-elements/TemplateFooter/TemplateFooter";
// types
import { PropsType } from "../../../types/Types";
// functions
import {
  formatDate,
  formatTime,
  formatDateNoYear,
} from "../../../functions/Functions";

const T2 = (props: PropsType) => {
  // variables
  const [currentBlock, setCurrentBlock] = useState<string>("left");

  // functions

  // useEffects
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentBlock]);

  //COPY COPY COPY COPY COPY COPY COPY COPY COPY COPY COPY COPY COPY//
  /* COPY - rsvp deadline */
  const todayDate = new Date();
  let rsvpDeadlineDate = new Date(
    `${props.publicationData?.rsvp_deadline!}T23:59:59`
  );
  //dev
  // rsvpDeadlineDate = new Date(`1111-11-11T23:59:59`);

  /* COPY - rsvp response submit */
  const [inputRequired, setInputRequired] = useState<string>("");
  const [isSubmitted, SetIsSubmitted] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  function handleRsvpSubmit() {
    checkRsvpResponseFilled();
  }

  function checkRsvpResponseFilled() {
    let isFilled = true;

    if (formRef.current?.guestName.value) {
    } else {
      isFilled = false;
    }

    if (formRef.current?.attendance.value) {
    } else {
      isFilled = false;
    }

    if (isFilled) {
      submitRsvpResponse();
    } else {
      setInputRequired("input-required");

      setTimeout(() => {
        setInputRequired("");
      }, 2500);
    }
  } //end of input check

  async function submitRsvpResponse() {
    const responseData = {
      guest_name: formRef.current?.guestName.value,
      attendance: formRef.current?.attendance.value,
      question_1_response: formRef.current?.question1Response?.value,
      question_2_response: formRef.current?.question2Response?.value,
    };

    try {
      await axios.post(
        `${WebsiteURL().serverURL()}/api/publications/rsvp-submit/${
          props.publicationData?.publication_url
        }`,
        responseData
      );

      SetIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting RSVP:", error);
    }
  } //end of submitRsvpResponse

  return (
    <div className="template" id="t2">
      <Helmet>
        <title>
          {props.publicationData?.event_name
            ? props.publicationData.event_name
            : "Pixely RSVP Website"}
        </title>
        <meta
          name="description"
          content={`${props.publicationData?.invitation_message}`}
        />
      </Helmet>

      <div className={`mobile-container ${currentBlock}`}>
        {/* ****************** invitation container **************** */}
        <div className="container" id="invitation-container">
          <img
            className="icon-img"
            id="stick-mask-img"
            src={StickMask}
            alt="stick-mask-icon"
          />

          <div className="block text-block">
            <h1 className="rsvp-heading">RSVP</h1>

            {/* invitation */}
            <p className="invitation-message">
              {props.publicationData?.invitation_message}
            </p>

            <div className="event-notice-container-wrapper">
              {/* event info */}
              <div className="event-container">
                <span className="event-span date">
                  <span className="event-property">When: </span>
                  {formatDate(props.publicationData?.event_date!)}
                </span>

                <span className="event-span time">
                  <span className="event-property">Time: </span>
                  {formatTime(props.publicationData?.event_time!)}
                </span>

                {props.publicationData?.event_location_name ? (
                  <span className="event-span location-top">
                    <span className="event-property">Where: </span>
                    {props.publicationData?.event_location_name},{" "}
                    {props.publicationData?.event_location_address}
                  </span>
                ) : (
                  <span className="event-span location-top">
                    {props.publicationData?.event_location_address}
                  </span>
                )}
              </div>

              {/* notice */}
              {props.publicationData?.option_notice ? (
                <div className="notice-container">
                  <h2 className="notice-heading">Notice</h2>

                  <span className="notice-span">
                    {props.publicationData?.option_notice}
                  </span>
                </div>
              ) : null}
            </div>

            <div
              className="t2-button"
              onClick={() => {
                setCurrentBlock("right");
              }}
            >
              <span className="rsvp-deadline-date">
                (Please reply by{" "}
                {formatDateNoYear(props.publicationData?.rsvp_deadline)})
              </span>
              <span>Respond</span>
            </div>
          </div>

          <TemplateFooter logo="black_" />
        </div>

        {/* ****************** form container **************** */}
        <div className="container" id="form-container">
          <img
            className="icon-img"
            id="firecracker-img"
            src={Firecracker}
            alt="firecracker-icon"
          />

          <div className="block text-block">
            <div
              className="form-back-button"
              onClick={() => {
                setCurrentBlock("left");
              }}
            >
              <BackArrow />
              <span>Back</span>
            </div>

            {todayDate > rsvpDeadlineDate ? (
              <span className="rsvp-deadline-span passed">
                Thank you for your interest, but unfortunately, the RSVP
                deadline has passed and we can no longer accept responses.
              </span>
            ) : isSubmitted ? (
              <div className="rsvp-submitted-container">
                <div className="rsvp-submitted-message-wrapper">
                  <span className="rsvp-submitted-heading">Thank you</span>
                  <span className="rsvp-submitted-span">
                    Your response has been submitted successfully.
                  </span>
                  {formRef.current?.attendance.value === "yes" ? (
                    <span className="rsvp-submitted-span">
                      We look forward to your presence. However, if your
                      availability changes, please feel free to resubmit your
                      RSVP with the same name.
                    </span>
                  ) : (
                    <span className="rsvp-submitted-span">
                      We are sorry you won't be able to join us. If your
                      availability changes, please feel free to resubmit your
                      RSVP with the same name.
                    </span>
                  )}
                </div>

                <div
                  className="t2-button"
                  onClick={() => {
                    SetIsSubmitted(false);
                  }}
                >
                  <span>Submit for Someone Else</span>
                </div>
              </div>
            ) : (
              <>
                <form ref={formRef}>
                  <div className="questions-container">
                    <div className="question-wrapper input-question-wrapper">
                      <div className="input-wrapper">
                        <label className="label-question">Your name:</label>

                        <input className="input-input" name="guestName"></input>
                      </div>
                    </div>

                    <div className="question-wrapper radio-question-wrapper">
                      <div className="input-wrapper">
                        <input
                          className="input-radio"
                          id="attendance-yes"
                          type="radio"
                          name="attendance"
                          value="yes"
                        ></input>

                        <label className="label-radio" htmlFor="attendance-yes">
                          <div className="radio-box"></div>Count me in!
                        </label>
                      </div>

                      <div className="input-wrapper">
                        <input
                          className="input-radio"
                          id="attendance-no"
                          type="radio"
                          name="attendance"
                          value="no"
                        ></input>

                        <label className="label-radio" htmlFor="attendance-no">
                          <div className="radio-box"></div>I’ll have to pass
                        </label>
                      </div>
                    </div>

                    {props.publicationData?.option_questions &&
                      props.publicationData?.option_questions?.length! > 0 &&
                      props.publicationData?.option_questions?.map(
                        (optionQuestion, index) => {
                          return (
                            <div
                              key={index}
                              className={`question-wrapper ${
                                optionQuestion.answer_type === "Radio"
                                  ? "radio-question-wrapper"
                                  : "input-question-wrapper"
                              }`}
                            >
                              {optionQuestion.answer_type === "Input" ? (
                                <div className="input-wrapper">
                                  <label className="label-question">
                                    {optionQuestion.question}
                                  </label>

                                  <input
                                    className="input-input"
                                    name={`question${index + 1}Response`}
                                  />
                                </div>
                              ) : (
                                <>
                                  <label className="label-question">
                                    {optionQuestion.question}
                                  </label>

                                  {optionQuestion.answer_choices.map(
                                    (childChoice, childIndex) => {
                                      return (
                                        <div
                                          key={childIndex}
                                          className="input-wrapper"
                                        >
                                          <input
                                            className="input-radio"
                                            id={`option-question-${childChoice}`}
                                            type="radio"
                                            name={`question${
                                              index + 1
                                            }Response`}
                                            value={`${childChoice}`}
                                          ></input>

                                          <label
                                            className="label-radio"
                                            htmlFor={`option-question-${childChoice}`}
                                          >
                                            <div className="radio-box"></div>

                                            {childChoice}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </div>
                          );
                        }
                      )}
                  </div>

                  <div
                    className={`t2-button submit-button ${inputRequired}`}
                    onClick={() => {
                      handleRsvpSubmit();
                    }}
                  >
                    <span className="submit-button-span">Submit</span>
                  </div>
                </form>
              </>
            )}
          </div>

          <TemplateFooter logo="black_black" />
        </div>
      </div>
    </div>
  );
};

export default T2;
