import { useState, useRef } from "react";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
import { Helmet } from "react-helmet-async";
// visuals
// elements
import TemplateFooter from "../../template-elements/TemplateFooter/TemplateFooter";
// types
import { PropsType } from "../../../types/Types";
// functions
import {
  formatDate,
  formatTime,
  formatDateNoYear,
} from "../../../functions/Functions";

const T4 = (props: PropsType) => {
  // variables

  // functions

  // useEffects

  /* COPY - rsvp deadline */
  const todayDate = new Date();
  let rsvpDeadlineDate = new Date(
    `${props.publicationData?.rsvp_deadline!}T23:59:59`
  );
  //dev
  // rsvpDeadlineDate = new Date(`1111-11-11T23:59:59`);

  /* COPY - rsvp response submit */
  const [inputRequired, setInputRequired] = useState<string>("");
  const [isSubmitted, SetIsSubmitted] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  function handleRsvpSubmit() {
    checkRsvpResponseFilled();
  }

  function checkRsvpResponseFilled() {
    let isFilled = true;

    if (formRef.current?.guestName.value) {
    } else {
      isFilled = false;
    }

    if (formRef.current?.attendance.value) {
    } else {
      isFilled = false;
    }

    if (isFilled) {
      submitRsvpResponse();
    } else {
      setInputRequired("input-required");

      setTimeout(() => {
        setInputRequired("");
      }, 2500);
    }
  } //end of input check

  async function submitRsvpResponse() {
    const responseData = {
      guest_name: formRef.current?.guestName.value,
      attendance: formRef.current?.attendance.value,
      question_1_response: formRef.current?.question1Response?.value,
      question_2_response: formRef.current?.question2Response?.value,
    };

    try {
      await axios.post(
        `${WebsiteURL().serverURL()}/api/publications/rsvp-submit/${
          props.publicationData?.publication_url
        }`,
        responseData
      );

      SetIsSubmitted(true);
    } catch (error) {
      console.error("Error submitting RSVP:", error);
    }
  } //end of submitRsvpResponse

  return (
    <div className="template" id="t4">
      <Helmet>
        <title>
          {props.publicationData?.event_name
            ? props.publicationData.event_name
            : "Pixely RSVP Website"}
        </title>
        <meta
          name="description"
          content={`${props.publicationData?.invitation_message}`}
        />
      </Helmet>

      <div className="mobile-container">
        {/* ****************** invitation container **************** */}
        <div className="container" id="invitation-container">
          <div className="blur-container"></div>

          <div className="scroll-container">
            <h1 className="rsvp-heading">
              <span>R</span>
              <span>S</span>
              <span>V</span>
              <span>P</span>
            </h1>

            {/* invitation */}
            <p className="invitation-message">
              {props.publicationData?.invitation_message}
            </p>

            {/* event info */}
            <div className="event-info-container">
              <span className="event-info-span date">
                {formatDate(props.publicationData?.event_date!)}
              </span>

              <span className="event-info-span time">
                Starts at {formatTime(props.publicationData?.event_time!)}
              </span>

              {props.publicationData?.event_location_name ? (
                <>
                  <span className="event-info-span location-top">
                    {props.publicationData?.event_location_name}
                  </span>
                  <span className="event-info-span location-bottom">
                    {props.publicationData?.event_location_address}
                  </span>
                </>
              ) : (
                <span className="event-info-span location-top">
                  {props.publicationData?.event_location_address}
                </span>
              )}
            </div>

            {/* notice */}
            {props.publicationData?.option_notice ? (
              <div className="notice-container">
                <h2 className="notice-heading">
                  <span>N</span>
                  <span>O</span>
                  <span>T</span>
                  <span>I</span>
                  <span>C</span>
                  <span>E</span>
                </h2>

                <span className="notice-span">
                  {props.publicationData?.option_notice}
                </span>
              </div>
            ) : null}

            {/* ******************** form container ****************** */}
            <div id="form-container">
              {todayDate > rsvpDeadlineDate ? (
                <span className="rsvp-deadline-span passed">
                  Thank you for your interest, but unfortunately, the RSVP
                  deadline has passed and we can no longer accept responses.
                </span>
              ) : isSubmitted ? (
                <div className="rsvp-submitted-container">
                  <div className="rsvp-submitted-message-wrapper">
                    <span className="rsvp-submitted-heading">Thank you</span>
                    <span className="rsvp-submitted-span">
                      Your response has been submitted successfully.
                    </span>
                    {formRef.current?.attendance.value === "yes" ? (
                      <span className="rsvp-submitted-span">
                        We look forward to your presence. However, if your
                        availability changes, please feel free to resubmit your
                        RSVP with the same name.
                      </span>
                    ) : (
                      <span className="rsvp-submitted-span">
                        We are sorry you won't be able to join us. If your
                        availability changes, please feel free to resubmit your
                        RSVP with the same name.
                      </span>
                    )}
                  </div>

                  <div
                    className="t4-button"
                    onClick={() => {
                      SetIsSubmitted(false);
                    }}
                  >
                    Submit for Someone Else
                  </div>
                </div>
              ) : (
                <>
                  <span className="rsvp-deadline-span">
                    Please RSVP by{" "}
                    {formatDateNoYear(props.publicationData?.rsvp_deadline!)}
                  </span>

                  <form ref={formRef}>
                    <div className="questions-container">
                      <div className="question-wrapper input-question-wrapper">
                        <div className="input-wrapper">
                          <label className="label-question">Your name:</label>

                          <input
                            className="input-input"
                            name="guestName"
                            placeholder=""
                          ></input>
                        </div>
                      </div>

                      <div className="question-wrapper radio-question-wrapper">
                        <label className="label-question">
                          Will you be joining us?
                        </label>

                        <div className="input-wrapper">
                          <input
                            className="input-radio"
                            id="attendance-yes"
                            type="radio"
                            name="attendance"
                            value="yes"
                          ></input>

                          <label
                            className="label-radio"
                            htmlFor="attendance-yes"
                          >
                            <div className="radio-box"></div>

                            <span>Yes</span>
                          </label>
                        </div>

                        <div className="input-wrapper">
                          <input
                            className="input-radio"
                            id="attendance-no"
                            type="radio"
                            name="attendance"
                            value="no"
                          ></input>

                          <label
                            className="label-radio"
                            htmlFor="attendance-no"
                          >
                            <div className="radio-box"></div>

                            <span>No</span>
                          </label>
                        </div>
                      </div>

                      {props.publicationData?.option_questions &&
                        props.publicationData?.option_questions?.length! > 0 &&
                        props.publicationData?.option_questions?.map(
                          (optionQuestion, index) => {
                            return (
                              <div
                                key={index}
                                className={`question-wrapper ${
                                  optionQuestion.answer_type === "Radio"
                                    ? "radio-question-wrapper"
                                    : "input-question-wrapper"
                                }`}
                              >
                                {optionQuestion.answer_type === "Input" ? (
                                  <div className="input-wrapper">
                                    <label className="label-question">
                                      {optionQuestion.question}
                                    </label>

                                    <input
                                      className="input-input"
                                      name={`question${index + 1}Response`}
                                      placeholder=""
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <label className="label-question">
                                      {optionQuestion.question}
                                    </label>

                                    {optionQuestion.answer_choices.map(
                                      (childChoice, childIndex) => {
                                        return (
                                          <div
                                            key={childIndex}
                                            className="input-wrapper"
                                          >
                                            <input
                                              className="input-radio"
                                              id={`option-question-${childChoice}`}
                                              type="radio"
                                              name={`question${
                                                index + 1
                                              }Response`}
                                              value={`${childChoice}`}
                                            ></input>

                                            <label
                                              className="label-radio"
                                              htmlFor={`option-question-${childChoice}`}
                                            >
                                              <div className="radio-box"></div>

                                              <span>{childChoice}</span>
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>

                    <div
                      className={`t4-button submit-button ${inputRequired}`}
                      onClick={() => {
                        handleRsvpSubmit();
                      }}
                    >
                      <span className="submit-button-span">Submit</span>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <TemplateFooter logo="white_white" />
    </div>
  );
};

export default T4;
