import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import NotFound from "../../main-pages/NotFound/NotFound";
import ServerURL from "../../../assets/database/WebsiteURL";
// visuals
import { ReactComponent as CheckIcon } from "../../../assets/visuals/icons/create/check_icon.svg";

interface ConfirmationDataType {
  publication_url: string;
  user_display_name: string;
}

const CreateConfirmation = () => {
  //variables
  const { publication_id } = useParams();
  const [confirmationData, setConfirmationData] =
    useState<ConfirmationDataType | null>(null);
  const [isLoadingDone, setIsLoadingDone] = useState(false);

  //functions

  //useEffect
  useEffect(() => {
    const getConfirmationData = async () => {
      try {
        const response = await axios.get(
          `${ServerURL().serverURL()}/api/publications/confirmation/${publication_id}`
        );
        const { data } = response;
        setConfirmationData(data);
        setIsLoadingDone(true);
      } catch (error) {
        console.error("Error retrieving confirmation data:", error);
        setIsLoadingDone(true);
      }
    };

    getConfirmationData();
  }, [publication_id]);

  // <></> <== empty tag for <Notfound/> Do not remove
  return (
    <>
      {isLoadingDone ? (
        confirmationData ? (
          <main className="main-page" id="confirmation-page">
            <>
              <div className="confirmation-text-wrapper">
                <div id="check-icon-wrapper">
                  <CheckIcon />
                </div>

                <h1 className="confirmation-heading">
                  RSVP website has been created successfully.
                </h1>

                <span id="url-span">
                  The website address is{" "}
                  <Link
                    id="url-link"
                    to={`${ServerURL().clientURL()}/${
                      confirmationData.publication_url
                    }
                      `}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${ServerURL().clientURL()}/${
                      confirmationData.publication_url
                    }
                      `}
                  </Link>
                </span>

                <Link
                  className="button"
                  to={`${ServerURL().clientURL()}/main/dashboard/${
                    confirmationData.publication_url
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Checkout Dashboard</span>
                </Link>
              </div>

              <div className="confirmation-button-wrapper">
                <span id="thank-span">
                  <b>Thank you for using our service.</b>
                </span>

                <Link className="button" id="confirmation-home-button" to="/">
                  <span>Back To Home Page</span>
                </Link>
              </div>
            </>
          </main>
        ) : (
          <NotFound />
        )
      ) : (
        <main className="main-page" id="confirmation-page"></main>
      )}
    </>
  );
};

export default CreateConfirmation;
