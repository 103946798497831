import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
// import visuals
import { ReactComponent as EmptyHeart } from "../../../assets/visuals/icons/empty_heart.svg";
import { ReactComponent as FilledHeart } from "../../../assets/visuals/icons/filled_heart.svg";
// import context
import { useUserProfileContext } from "../../../contexts/userProfileContext";

type TemplateDetailType = {
  template_id: string;
  template_name: string;
  pricing_level: number;

  preview_url: string;
  product_detail_url: string;

  thumbnail: string;
};

const ProductDetail = () => {
  //variables
  const [otherTemplateList, setOtherTemplateList] = useState<
    TemplateDetailType[] | null
  >(null);
  const [templateDetail, setTemplateDetail] = useState<
    TemplateDetailType | null | undefined
  >(null);
  const { product_detail_url } = useParams();
  const [selectedDescription, setSelectedDescription] = useState("detail");
  // const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userProfile } = useUserProfileContext();

  //functions
  function handleRadioChange(e: any) {
    setSelectedDescription(e.target.id);
  }

  function handleCreateButton() {
    //check if the user is logged in
    if (userProfile === undefined) {
      //not logged in
      navigate("/main/login");
    } else {
      //logged in
      navigate("./create");
    }
  }

  //useEffect
  useEffect(() => {
    setOtherTemplateList(null);
    setTemplateDetail(null);

    const getTemplateDetail = async () => {
      try {
        // GET all template
        const response = await axios.get(
          `${WebsiteURL().serverURL()}/api/templates`,
          {
            withCredentials: true,
          }
        );

        // FIND selected template
        const foundTemplate = response.data.find(
          (template: any) => template.product_detail_url === product_detail_url
        );
        setTemplateDetail(foundTemplate);

        // FILTER other templates
        const otherTemplates = response.data
          .filter(
            (template: any) =>
              template.product_detail_url !== product_detail_url
          )
          .slice(0, 5);
        setOtherTemplateList(otherTemplates);
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 404) {
            // No matching data
            console.error("No data found.");
          } else {
            // Other server error
            console.error("Server error:", error);
          }
        } else {
          // Network error or similar
          console.error("Network error:", error);
        }
      }
    };

    getTemplateDetail();
  }, [product_detail_url]);

  return (
    <main className="main-page" id="product-detail-page">
      {/* 1.breadcrumb */}
      <div className="breadcrumb">
        <Link to="/">Home</Link>
        {` > `}
        <Link to="/main/death-announcements">Death Announcements</Link>
        {` > `}
        <span>{templateDetail?.template_name}</span>
      </div>

      {templateDetail === undefined ? (
        <div className="template-not-found-container">
          <span className="template-not-found-span">Template Not Found</span>
        </div>
      ) : (
        <div className="template-information-wrapper">
          {/* 2.template detail */}
          {/* 2-1.template detail, preview (left-side) */}
          <div className="template-preview-wrapper">
            {templateDetail ? (
              <>
                <iframe
                  className="template-preview-iframe"
                  title="template-preview"
                  src={`${WebsiteURL().clientURL()}/${
                    templateDetail?.preview_url
                  }`}
                ></iframe>

                <img
                  className="template-preview-img"
                  alt="mobile-preview"
                  src={templateDetail?.thumbnail}
                />
              </>
            ) : (
              // <div className="template-preview-iframe loading"></div>
              <></>
            )}
          </div>

          {/* 2-2.template detail, description (right-side) */}
          <div className="template-description-wrapper">
            <div className="name-block">
              <div className="name-span-wrapper">
                <span className="name-span" id="sub-name">
                  Death Announcement
                </span>
              </div>
              <div className="name-span-wrapper">
                <span
                  className={`name-span ${templateDetail ? `` : `loading`}`}
                  id="main-name"
                >
                  {templateDetail?.template_name}
                </span>
              </div>
              <div className="name-span-wrapper">
                <span
                  className={`name-span ${templateDetail ? `` : `loading`}`}
                  id="pricing-span"
                >
                  {templateDetail?.pricing_level === 0
                    ? "Free"
                    : templateDetail?.pricing_level === 1
                    ? "$2.99 CAD"
                    : ""}
                </span>
              </div>
            </div>

            <div className="call-to-action-button-container">
              <Link
                className={`call-to-action-button ${
                  templateDetail ? `` : `loading`
                }`}
                id="create-button"
                to="./create"
                // onClick={handleCreateButton}
              >
                <span className="call-to-action-span">
                  Create Death Announcement
                </span>
              </Link>

              <Link
                className={`call-to-action-button ${
                  templateDetail ? `` : `loading`
                }`}
                id="preview-button"
                to={`${WebsiteURL().clientURL()}/${
                  templateDetail?.preview_url
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="call-to-action-span">Preview</span>
              </Link>
            </div>

            <div className="description-block">
              <div
                className="description-radio-wrapper"
                onChange={handleRadioChange}
              >
                <div className="description-radio-element">
                  <input
                    type="radio"
                    name="select-description"
                    id="detail"
                    defaultChecked
                  ></input>
                  <label htmlFor="detail">Details</label>
                </div>

                <div className="description-radio-element">
                  <input
                    type="radio"
                    name="select-description"
                    id="features"
                  ></input>
                  <label htmlFor="features">Features</label>
                </div>
              </div>

              <div className="description-text-wrapper">
                {selectedDescription === "detail" ? (
                  <>
                    <p className="description-details-p">
                      This mobile death announcement template is a free,
                      compassionate service to inform and honor loved ones.
                      Share funeral details, with Pixely's support during your
                      time of loss.
                    </p>
                  </>
                ) : selectedDescription === "features" ? (
                  <>
                    <p className="description-features-p">
                      -
                      <span className="description-features-property">
                        Funeral Service Guide:{" "}
                      </span>
                      <span className="description-features-value">
                        Provide information about the funeral service.
                      </span>
                    </p>

                    <p className="description-features-p">
                      -
                      <span className="description-features-property">
                        Tribute Comment Section:{" "}
                      </span>
                      <span className="description-features-value">
                        Allow friends and family to leave heartfelt messages and
                        share memories.
                      </span>
                    </p>

                    <p className="description-features-p">
                      -
                      <span className="description-features-property">
                        Donation Link:{" "}
                      </span>
                      <span className="description-features-value">
                        Add a donation link of your choice.
                      </span>
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 3.reviews */}

      {/* 4.other templates */}
      <div className="other-templates-section">
        <h3 className="other-templates-heading">Other Templates</h3>

        <div className="other-templates-carousel-container">
          {otherTemplateList ? (
            <>
              {otherTemplateList?.map((template, index) => {
                return (
                  <Link
                    to={`/main/death-announcements/${template.product_detail_url}`}
                    className="other-templates-element"
                    key={index}
                  >
                    <div className="other-templates-img-wrapper">
                      <img
                        className="other-templates-img"
                        src={template?.thumbnail}
                        alt="template-preview"
                      ></img>
                    </div>
                    <span className="other-templates-span">
                      {template?.template_name}
                    </span>
                  </Link>
                );
              })}

              {[...Array(5 - otherTemplateList.length)].map((_, index) => (
                <div
                  className="other-templates-element invisible-placeholder"
                  key={`empty-${index}`}
                >
                  <div className="other-templates-img-wrapper"></div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="other-templates-element">
                <div className="other-templates-img-wrapper"></div>
              </div>

              <div className="other-templates-element">
                <div className="other-templates-img-wrapper"></div>
              </div>

              <div className="other-templates-element">
                <div className="other-templates-img-wrapper"></div>
              </div>

              <div className="other-templates-element">
                <div className="other-templates-img-wrapper"></div>
              </div>

              <div className="other-templates-element">
                <div className="other-templates-img-wrapper"></div>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default ProductDetail;
