import { createContext, useContext, ReactNode, useState, useRef } from "react";
import { FollowUpQuestionType } from "../types/Types";

// Define the shape of your context data
interface CreatePageContextData {
  // form tools
  createFormRef: React.RefObject<HTMLFormElement>;

  currentStage: string;
  setCurrentStage: React.Dispatch<React.SetStateAction<string>>;

  // first stage
  selectedTemplateId: string;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<string>>;

  selectedTemplateName: string;
  setSelectedTemplateName: React.Dispatch<React.SetStateAction<string>>;

  mainPhoto: File | undefined;
  setMainPhoto: React.Dispatch<React.SetStateAction<File | undefined>>;

  galleryPhotos: File[];
  setGalleryPhotos: React.Dispatch<React.SetStateAction<File[]>>;

  // second stage
  secondStageFilled: Boolean;
  setSecondStageFilled: React.Dispatch<React.SetStateAction<Boolean>>;

  optionQuestions: FollowUpQuestionType[];
  setOptionQuestions: React.Dispatch<
    React.SetStateAction<FollowUpQuestionType[]>
  >;

  optionNoticeEnabled: string;
  setOptionNoticeEnabled: React.Dispatch<React.SetStateAction<string>>;

  optionPasswordEnabled: string;
  setOptionPasswordEnabled: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with an initial value
const CreatePageContext = createContext<CreatePageContextData | undefined>(
  undefined
);

// Optional: Create a custom hook for consuming the context
export const useCreatePageContext = () => {
  const context = useContext(CreatePageContext);
  if (!context) {
    throw new Error(
      "useCreatePageContext must be used within a CreatePageContextProvider"
    );
  }
  return context;
};

// Create a component that will provide the context
interface CreatePageContextProviderProps {
  children: ReactNode;
}

export const CreatePageContextProvider = ({
  children,
}: CreatePageContextProviderProps) => {
  // variables
  // form
  const createFormRef = useRef<HTMLFormElement>(null);
  //dev, has to be set to "first"
  const [currentStage, setCurrentStage] = useState<string>("first");

  // first stage
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");

  const [mainPhoto, setMainPhoto] = useState<File | undefined>();
  const [galleryPhotos, setGalleryPhotos] = useState<File[]>([]);

  // second stage
  const [optionQuestions, setOptionQuestions] = useState<
    FollowUpQuestionType[]
  >([]);
  const [optionNoticeEnabled, setOptionNoticeEnabled] =
    useState<string>("disabled");
  const [optionPasswordEnabled, setOptionPasswordEnabled] =
    useState<string>("disabled");

  const [secondStageFilled, setSecondStageFilled] = useState<Boolean>(false);

  const contextValue: CreatePageContextData = {
    // form
    createFormRef,

    currentStage,
    setCurrentStage,

    // first stage
    selectedTemplateId,
    setSelectedTemplateId,

    selectedTemplateName,
    setSelectedTemplateName,

    mainPhoto,
    setMainPhoto,

    galleryPhotos,
    setGalleryPhotos,

    // second stage
    secondStageFilled,
    setSecondStageFilled,

    optionQuestions,
    setOptionQuestions,

    optionNoticeEnabled,
    setOptionNoticeEnabled,

    optionPasswordEnabled,
    setOptionPasswordEnabled,
  };

  return (
    <CreatePageContext.Provider value={contextValue}>
      {children}
    </CreatePageContext.Provider>
  );
};
