import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
import { useUserProfileContext } from "../../../contexts/userProfileContext";
// visuals
import Logo from "../../../assets/visuals/pixely-logos/QR_code_logo.png";
import { ReactComponent as ShareIcon } from "../../../assets/visuals/icons/dashboard/share_icon.svg";
import { ReactComponent as BackArrowIcon } from "../../../assets/visuals/icons/dashboard/back_arrow_icon.svg";
// Mantine
import { DonutChart } from "@mantine/charts";
import { Button, Table, CopyButton } from "@mantine/core";
// QR code
import QRCode from "qrcode.react";
// types
import { PublicationDataType } from "../../../types/Types";

const RsvpDashboard = () => {
  // variables
  const navigate = useNavigate();
  const { publication_url } = useParams();
  const [publicationData, setPublicationData] =
    useState<PublicationDataType>(null);
  const { userProfile, setUserProfile } = useUserProfileContext();

  // 1.url
  const rsvpWebsiteUrl = `${WebsiteURL().clientURL()}/${publication_url}`;

  // 2.qr code
  const QRcodeRef = useRef<any>(null);
  const [QRcodeCopyButton, setQRcodeCopyButton] =
    useState<string>("Copy QR Code");

  // 3.invitee number
  const inviteeInputRef = useRef<HTMLInputElement>(null);
  const [numberOfInvitees, setNumberOfInvitees] = useState<number>(0);
  const [inviteeSaveButton, setInviteeSaveButton] = useState<string>("save");

  // 4.table
  const [csvTable, setCsvTable] = useState<string>("");
  const [responsesInRow, setResponsesInRow] = useState<any[]>([]);

  // 5.attendance chart data
  const [attendanceChart, setAttendanceChart] = useState<any[]>([]);

  // functions
  async function handleInviteeNumberSave() {
    if (inviteeInputRef.current) {
      const inviteeNumber = +inviteeInputRef.current.value;
      setNumberOfInvitees(inviteeNumber);

      try {
        await axios.post(
          `${WebsiteURL().serverURL()}/api/publications/invitee-number/${publication_url}`,
          { inviteeNumber },
          {
            withCredentials: true,
          }
        );
      } catch (error) {
        console.error("Error saving invitee number:", error);
      }
    }
  }

  async function handleQRcodeCopy() {
    const canvas = QRcodeRef.current?.querySelector("canvas");
    canvas.getContext("2d");
    canvas.toBlob((blob: any) => {
      navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
    }, "image/png");
  }

  // useEffects
  useEffect(() => {
    const getUserDataAndFetch = async () => {
      // 1.check login status
      if (userProfile) {
        fetchPublicationData(publication_url);
      } else {
        try {
          const { data } = await axios.get(
            `${WebsiteURL().serverURL()}/auth/profile`,
            {
              withCredentials: true,
            }
          );

          if (data.user) {
            setUserProfile(data.user);

            fetchPublicationData(publication_url);
          } else {
            navigate("/main/login");
          }
        } catch (error: any) {
          navigate("/main/login");

          if (error.response) {
            if (error.response.status === 404) {
              console.error("No data found.");
            } else {
              console.error("Server error:", error);
            }
          } else {
            console.error("Network error:", error);
          }
        }
      }
    };

    async function fetchPublicationData(publicationURL: any) {
      // 2.fetch data

      try {
        const response = await axios.get(
          `${WebsiteURL().serverURL()}/api/publications/${publicationURL}`,
          {
            withCredentials: true,
          }
        );

        setPublicationData(response.data);
      } catch (error) {
        console.error("Error fetching published announcements:", error);
      } finally {
      }
    }

    getUserDataAndFetch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Number of Invitees //
    if (inviteeInputRef.current) {
      inviteeInputRef.current.value = publicationData?.number_of_invitees
        ? "" + publicationData?.number_of_invitees
        : "0";

      setNumberOfInvitees(
        publicationData?.number_of_invitees
          ? publicationData?.number_of_invitees
          : 0
      );
    }

    // RSVP responses //
    if (
      publicationData?.rsvp_responses !== undefined &&
      publicationData?.rsvp_responses.length > 0
    ) {
      // for copy
      const tableString = publicationData.rsvp_responses
        .map((response, index) => {
          const responseValues = [
            response.guest_name,
            response.attendance,
            response.question_1_response,
            response.question_2_response,
          ];

          return responseValues.join("\t");
        })
        .join("\n");

      setCsvTable(tableString);

      // for table
      setResponsesInRow(
        publicationData.rsvp_responses.map((response, index) => (
          <Table.Tr key={index}>
            <Table.Td>{response.guest_name}</Table.Td>
            <Table.Td>{response.attendance}</Table.Td>
            <Table.Td>
              {response.attendance === "no"
                ? "-"
                : response.question_1_response}
            </Table.Td>
            <Table.Td>
              {response.attendance === "no"
                ? "-"
                : response.question_2_response}
            </Table.Td>
          </Table.Tr>
        ))
      );
    } else {
      // Zero responses
    }
  }, [publicationData]);

  useEffect(() => {
    // Attendance DonutChart //
    if (publicationData?.rsvp_responses) {
      // extract the choices
      const attendanceChoices = ["yes", "no"];
      const colors = [
        "green",
        "red",
        "gray", // For "no response yet"
      ];

      const yesCount = publicationData.rsvp_responses.filter(
        (response) => response.attendance === "yes"
      ).length;

      const noCount = publicationData.rsvp_responses.filter(
        (response) => response.attendance === "no"
      ).length;

      const gatheredData = attendanceChoices.map((choice: string) => ({
        name: choice.charAt(0).toUpperCase() + choice.slice(1),
        value: choice === "yes" ? yesCount : choice === "no" ? noCount : 0,
        color: colors[attendanceChoices.indexOf(choice)],
      }));

      // if there is number_of_invitees...
      if (numberOfInvitees && numberOfInvitees - yesCount - noCount > -1) {
        gatheredData.push({
          name: "No response yet",
          value: numberOfInvitees - yesCount - noCount,
          color: colors[attendanceChoices.length],
        });
      }

      setAttendanceChart(gatheredData);
    }
  }, [numberOfInvitees, publicationData]);

  return (
    <main className="main-page" id="rsvp-dashboard-page">
      <div className="rsvp-dashboard-container">
        {/****************** left column **********************/}
        <div className="rsvp-column left">
          {publicationData ? (
            <>
              <Link className="back-button" to="/main/dashboard">
                <BackArrowIcon />
                <span>Back</span>
              </Link>

              <h1 className="event-name-heading">
                {publicationData.event_name}
              </h1>

              <div className="left-column-section first-section">
                <div
                  className="button"
                  id="rsvp-share-button"
                  onClick={() => {
                    navigator.share({
                      title: `${publicationData.event_name}`,
                      text: `${publicationData.invitation_message}`,
                      url: `${WebsiteURL().clientURL()}/${
                        publicationData.publication_url
                      }`,
                    });
                  }}
                >
                  <span>
                    <ShareIcon />
                    Send Invitation
                  </span>
                </div>

                {/* url copy */}
                <div className="link-wrapper url">
                  <span className="link-value">{rsvpWebsiteUrl}</span>

                  <CopyButton value={rsvpWebsiteUrl}>
                    {({ copied, copy }) => (
                      <Button
                        className="mantine-button"
                        color="#656565"
                        onClick={copy}
                        size="compact-xs"
                      >
                        {!copied ? "Copy URL" : "Copied URL"}
                      </Button>
                    )}
                  </CopyButton>
                </div>

                {/* qr code copy */}
                <div className="link-wrapper qr-code">
                  <div ref={QRcodeRef}>
                    <QRCode
                      className="link-value"
                      value={rsvpWebsiteUrl}
                      size={150}
                      imageSettings={{
                        src: Logo,
                        x: undefined,
                        y: undefined,
                        height: 37.5, // size / 4 = width,height
                        width: 37.5,
                        excavate: true,
                      }}
                    />
                  </div>

                  <Button
                    className="mantine-button"
                    color="#656565"
                    size="compact-xs"
                    onClick={(e) => {
                      setQRcodeCopyButton("Copied QR Code");

                      handleQRcodeCopy();

                      setTimeout(() => {
                        setQRcodeCopyButton("Copy QR Code");
                      }, 1000);
                    }}
                  >
                    {QRcodeCopyButton}
                  </Button>
                </div>
              </div>

              {/* number of invitee */}
              <div className="left-column-section invitee-section">
                <h3 className="column-heading">Number of Invitees</h3>

                <span className="invitee-span">
                  I have sent the invitation to{" "}
                  <input
                    ref={inviteeInputRef}
                    className="invitee-input"
                    type="number"
                    onChange={(e) => {
                      if (inviteeInputRef.current) {
                        inviteeInputRef.current.style.color = "#ff9000";
                      }
                    }}
                  ></input>{" "}
                  people.
                </span>

                <Button
                  className="mantine-button"
                  color="#ff9000"
                  size="compact-md"
                  onClick={(e) => {
                    setInviteeSaveButton("saved");
                    if (inviteeInputRef.current) {
                      inviteeInputRef.current.style.color = "#000000";
                    }

                    handleInviteeNumberSave();

                    setTimeout(() => {
                      setInviteeSaveButton("save");
                    }, 1000);
                  }}
                >
                  {inviteeSaveButton}
                </Button>
              </div>

              <div className="left-column-section response-section">
                <h3 className="column-heading">RSVP Responses</h3>

                <CopyButton value={csvTable}>
                  {({ copied, copy }) => (
                    <Button
                      className="mantine-button"
                      color="#656565"
                      onClick={copy}
                      size="compact-xs"
                    >
                      {!copied ? "copy table" : "copied table"}
                    </Button>
                  )}
                </CopyButton>

                <div className="table-container">
                  <Table
                    className="table"
                    highlightOnHover
                    horizontalSpacing="lg"
                  >
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Guest name</Table.Th>
                        <Table.Th>Attendance</Table.Th>
                        {publicationData.option_questions?.map(
                          (question, index) => (
                            <Table.Th key={index}>
                              Question {index + 1}
                            </Table.Th>
                          )
                        )}
                      </Table.Tr>
                    </Table.Thead>

                    <Table.Tbody>{responsesInRow}</Table.Tbody>
                  </Table>
                </div>
              </div>
            </>
          ) : null}
        </div>

        {/****************** right column **********************/}
        <div className="rsvp-column right">
          <div className="right-column-block">
            <h3 className="column-heading right-column-heading">ATTENDANCE</h3>

            {publicationData?.rsvp_responses !== undefined &&
            publicationData?.rsvp_responses.length > 0 ? (
              <DonutChart
                className="chart"
                withLabelsLine
                withLabels
                data={attendanceChart}
                thickness={30}
                style={{ width: 300, height: 240 }}
              />
            ) : (
              <div id="placeholder-chart-container">
                <div id="placeholder-chart"></div>
              </div>
            )}

            <div className="response-wrapper">
              <span className="response-value">
                {!publicationData?.rsvp_responses
                  ? 0
                  : publicationData.rsvp_responses.filter(
                      (response) => response.attendance === "yes"
                    ).length}
              </span>
              <span className="response-property">Guests said YES</span>
            </div>

            <div className="response-wrapper">
              <span className="response-value">
                {!publicationData?.rsvp_responses
                  ? 0
                  : publicationData.rsvp_responses.filter(
                      (response) => response.attendance === "no"
                    ).length}
              </span>
              <span className="response-property">Guests said NO</span>
            </div>

            {publicationData?.rsvp_responses ? (
              <div className="response-wrapper">
                <span className="response-value">
                  {!numberOfInvitees
                    ? 0
                    : numberOfInvitees -
                        publicationData.rsvp_responses.filter(
                          (response) => response.attendance === "yes"
                        ).length -
                        publicationData.rsvp_responses.filter(
                          (response) => response.attendance === "no"
                        ).length >
                      0
                    ? numberOfInvitees -
                      publicationData.rsvp_responses.filter(
                        (response) => response.attendance === "yes"
                      ).length -
                      publicationData.rsvp_responses.filter(
                        (response) => response.attendance === "no"
                      ).length
                    : 0}
                </span>
                <span className="response-property">
                  Guests has not responded yet
                </span>
              </div>
            ) : null}
          </div>

          {/************** Follow Up Question (Radio) *************/}
          {publicationData?.option_questions?.map((questionData, index) => {
            if (questionData.answer_type === "Radio") {
              // Extract the choices
              const choices = questionData.answer_choices;
              const colors = [
                "orange",
                "indigo",
                "pink",
                "grape",
                "violet",
                "yellow",
                "blue",
                "cyan",
                "teal",
                "lime",
                "red",
                "green",
              ];

              const responseLocation =
                index === 0 ? "question_1_response" : "question_2_response";

              // Count the responses for each choice
              const gatheredData = choices.map((choice: string) => ({
                name: choice,
                value:
                  publicationData.rsvp_responses?.filter(
                    (response) =>
                      response.attendance === "yes" &&
                      response[responseLocation] === choice
                  ).length || 0,
                color: colors[choices.indexOf(choice) % colors.length],
              }));

              return (
                <div key={index} className="right-column-block">
                  <h3 className="column-heading right-column-heading">
                    {questionData.question}
                  </h3>

                  {publicationData?.rsvp_responses !== undefined &&
                  publicationData?.rsvp_responses.length > 0 ? (
                    <DonutChart
                      className="chart"
                      withLabelsLine
                      withLabels
                      data={gatheredData}
                      thickness={30}
                      style={{ width: 300, height: 240 }}
                    />
                  ) : (
                    <div id="placeholder-chart-container">
                      <div id="placeholder-chart"></div>
                    </div>
                  )}

                  {questionData.answer_choices.map((choiceOption, index) => {
                    return (
                      <div key={index} className="response-wrapper">
                        <span className="response-value">
                          {!publicationData?.rsvp_responses
                            ? 0
                            : publicationData.rsvp_responses.filter(
                                (response) =>
                                  response.attendance === "yes" &&
                                  response[responseLocation] === choiceOption
                              ).length}
                        </span>
                        <span className="response-property">
                          {choiceOption}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </main>
  );
};

export default RsvpDashboard;
