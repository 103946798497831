import React from "react";
import contactInfo from "../../../assets/database/contact_info.json";
import ContactForm from "../../../components/interfaces/ContactForm/ContactForm";

const Contact = () => {
  return (
    <main className="main-page" id="contact-page">
      <h1 className="contact-heading">Contact Info</h1>

      <div className="contact-container">
        <div className="contact-group" id="info-group">
          <div className="contact-element" id="email-element">
            <span className="contact-property">Email:</span>

            <div className="contact-value-wrapper">
              <span className="contact-value">{contactInfo.email}</span>
            </div>
          </div>

          {/* <div className="contact-element" id="hours-element">
            <span className="contact-property">Customer Support Hours:</span>

            <div className="contact-value-wrapper">
              <div className="contact-value">
                {contactInfo.businessHours.map((value, index) => {
                  return (
                    <span className="business-hours-group" key={index}>
                      <span id="business-hours-day">{value.day}</span>
                      <span id="business-hours-hours">{value.hours}</span>
                    </span>
                  );
                })}
              </div>
            </div>
          </div> */}
        </div>

        <div className="contact-group">
          <span className="contact-property">Contact Form:</span>
          <ContactForm />
        </div>
      </div>
    </main>
  );
};

export default Contact;
