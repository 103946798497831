import { Link } from "react-router-dom";
// visuals
import { ReactComponent as FullLogoBlackBlack } from "../../../assets/visuals/pixely-logos/full_logo_black_black.svg";

const DashboardFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="main-footer" id="dashboard-footer">
      <div className="footer-container">
        <div className="footer-legal">
          <FullLogoBlackBlack />

          <div className="footer-legal-group">
            <div className="footer-anchor-wrapper">
              <Link className="footer-legal-link" to="/main/privacy-policy">
                Privacy Policy
              </Link>
            </div>

            <span className="footer-legal-text" id="divider">
              |
            </span>

            <div className="footer-anchor-wrapper">
              <Link
                className="footer-legal-link"
                to="/main/terms-and-conditions"
              >
                Terms and Conditions
              </Link>
            </div>

            <span className="footer-legal-text" id="divider">
              |
            </span>

            <span className="footer-legal-text">
              <span>© {currentYear} Pixely.&nbsp;</span>
              <span>All rights reserved.</span>
            </span>
          </div>
        </div>

        <nav className="footer-nav">
          <Link className="footer-nav-link" to="/main/templates">
            Templates
          </Link>

          <Link className="footer-nav-link" to="/main/contact">
            Contact Us
          </Link>
        </nav>
      </div>
    </footer>
  );
};

export default DashboardFooter;
