import updateDate from "../../../assets/database/legal_update_date.json";
import contactInfo from "../../../assets/database/contact_info.json";

const Page = () => {
  return (
    <main className="main-page" id="legal-page">
      <div className="heading-group">
        <h1 className="legal-heading">Privacy Policy</h1>
        <span>
          &#40;Last updated on {updateDate.privacyPolicyUpdateDate}&#41;
        </span>
      </div>

      <div className="body-group">
        <div className="body-element">
          <h2 className="body-element-heading">Introduction</h2>
          <p className="body-element-body">
            At Pixely, we value your privacy and are committed to protecting
            your personal information. This Privacy Policy explains how we
            collect, use, and safeguard the information we obtain from visitors
            to our website.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Information We Collect</h2>
          <p className="body-element-body">
            We use Google Analytics to collect and analyze information about
            your visit to our website. This includes details about your device,
            browser, location, and the pages you visit. Google Analytics helps
            us understand how our website is used and how we can improve it.
            Please note that Google Analytics may place cookies on your device
            as part of this process.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">How We Use Your Information</h2>
          <p className="body-element-body">
            The information collected through Google Analytics is used solely
            for the purpose of improving our website's content, functionality,
            and user experience. This data helps us make informed decisions
            about how to enhance our online presence.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Data Sharing and Sale</h2>
          <p className="body-element-body">
            We do not share, sell, or disclose the information collected through
            Google Analytics to any third parties. The data collected is
            strictly for our internal use to better serve our website visitors.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Your Choices</h2>
          <p className="body-element-body">
            You may choose to disable or block cookies through your browser
            settings or use browser add-ons to opt out of Google Analytics
            tracking. However, please be aware that this may impact your
            experience on our website.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Data Security</h2>
          <p className="body-element-body">
            We take appropriate measures to protect the data collected through
            Google Analytics. This includes security protocols and access
            controls to prevent unauthorized access or data breaches.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">
            Updates to this Privacy Policy
          </h2>
          <p className="body-element-body">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. Any changes will be posted on this page with an
            updated effective date.
          </p>
        </div>

        <div className="body-element">
          <h2 className="body-element-heading">Contact Us</h2>
          <p className="body-element-body">
            If you have any questions, concerns, or requests regarding your
            personal information or this Privacy Policy, please contact us
            at&nbsp;
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>.
          </p>
        </div>

        <div className="body-element">
          <p>
            Thank you for visiting Pixely. Your privacy is important to us, and
            we are dedicated to ensuring a safe and secure online experience.
          </p>
        </div>
      </div>

      <span
        className="back-to-top-button"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        Back to top
      </span>
    </main>
  );
};

export default Page;
