import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <main className="main-page" id="not-found-page">
      <div className="not-found-content-container">
        <h1 className="not-found-code">404</h1>

        <span className="not-found-message">
          Page that you are looking for cannot be found.
        </span>

        <Link className="button" to="/">
          <span>Return to home page</span>
        </Link>
      </div>
    </main>
  );
};

export default NotFound;
