import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { UserProfileContextProvider } from "./contexts/userProfileContext";
import App from "./components/App/App";
import "./styles/style.css";
import { createTheme, MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({});

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <UserProfileContextProvider>
        <MantineProvider theme={theme}>
          <App />
        </MantineProvider>
      </UserProfileContextProvider>
    </HelmetProvider>
  </React.StrictMode>
);
