import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import WebsiteURL from "../../../assets/database/WebsiteURL";
import { useUserProfileContext } from "../../../contexts/userProfileContext";
// visuals
import { ReactComponent as FullLogoWhiteWhite } from "../../../assets/visuals/pixely-logos/full_logo_white_white.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/visuals/icons/header/user_logout_icon.svg";

const DashboardHeader = () => {
  // variables
  const navigate = useNavigate();
  const { userProfile, setUserProfile } = useUserProfileContext();

  // functions
  const handleLogout = async () => {
    // navigate(0); //refresh page

    try {
      navigate("/");
      setUserProfile(null);

      await axios({
        method: "POST",
        url: `${WebsiteURL().serverURL()}/auth/logout`,
        withCredentials: true,
      }).then(() => {});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header id="dashboard-header">
      <div className="header-logo">
        <Link to="/">
          <FullLogoWhiteWhite />
        </Link>
      </div>

      <div className="header-left">
        <Link to="/main/dashboard" className="user-info-container">
          <img src={userProfile?.user_picture} alt="profile_picture" />
          <span>{userProfile?.user_display_name}</span>
        </Link>

        <div
          id="logout-button"
          onClick={() => {
            handleLogout();
          }}
        >
          <LogoutIcon />
          <span>Logout</span>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
