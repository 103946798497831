import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCreatePageContext } from "../../../contexts/createPageContext";
// create-components
import FirstStage from "./FirstStage/FirstStage";
import SecondStage from "./SecondStage/SecondStage";
import ThirdStage from "./ThirdStage/ThirdStage";
// Mantine
import { MantineProvider } from "@mantine/core";

const ProductCreate = () => {
  //variables
  const { product_url } = useParams();
  const {
    createFormRef,
    currentStage,
    setSelectedTemplateId,

    selectedTemplateName,
    setSelectedTemplateName,
  } = useCreatePageContext();

  //useEffects
  useEffect(() => {
    switch (product_url) {
      case "blanca":
        setSelectedTemplateId("t1");
        setSelectedTemplateName("Blanca");
        break;
      case "party-time":
        setSelectedTemplateId("t2");
        setSelectedTemplateName("Party Time");
        break;
      case "lussom":
        setSelectedTemplateId("t3");
        setSelectedTemplateName("Lussom");
        break;
      case "jungle-book":
        setSelectedTemplateId("t4");
        setSelectedTemplateName("Jungle Book");
        break;
      case "jungle-book-2":
        setSelectedTemplateId("t5");
        setSelectedTemplateName("Jungle Book 2");
        break;

      default:
        setSelectedTemplateId("");
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_url]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentStage]);

  return (
    <main className="main-page" id="create-page">
      <div className="create-modal">
        {/* Display */}
        <div className={`create-panel ${currentStage}`}>
          <h1 className="create-heading">
            Building RSVP Website with{" "}
            <span className="create-heading-template-name">
              {selectedTemplateName}
            </span>
          </h1>

          <div className="create-progress-bar">
            <div className="bubble" id="first-bubble">
              <span className="stage-number">1</span>
              <span className="stage-name">Event Info</span>
            </div>

            <div className="line"></div>

            <div className="bubble" id="second-bubble">
              <span className="stage-number">2</span>
              <span className="stage-name">Options</span>
            </div>

            <div className="line"></div>

            <div className="bubble" id="third-bubble">
              <span className="stage-number">3</span>
              <span className="stage-name">Review</span>
            </div>
          </div>
        </div>

        {/* Form */}
        <form className={`create-form ${currentStage}`} ref={createFormRef}>
          <MantineProvider
            theme={{
              colors: {
                black: [
                  "#fff", // Very light gray (almost white)
                  "#F8F9FA", // Light gray
                  "#E9ECEF", // Lighter gray
                  "#DEE2E6", // Light gray
                  "#CED4DA", // Medium gray
                  "#ADB5BD", // Darker gray
                  "#808080", // Even darker gray <<<<border color
                  "#495057", // Dark gray
                  "#343A40", // Very dark gray
                  "#222", // Black
                ],
              },
              primaryColor: "black",
            }}
          >
            <FirstStage />

            <SecondStage />

            <ThirdStage />
          </MantineProvider>
        </form>
      </div>
    </main>
  );
};

export default ProductCreate;
