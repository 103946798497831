import { useState, useEffect, useReducer } from "react";
import axios from "axios";
// systems
import WebsiteURL from "../../../../assets/database/WebsiteURL";
import ProductMetaData from "../../../../assets/database/general_system_data.json";
// contexts
import { useCreatePageContext } from "../../../../contexts/createPageContext";
// visuals
import { ReactComponent as VIcon } from "../../../../assets/visuals/icons/create/check_icon.svg";
import { ReactComponent as AddIcon } from "../../../../assets/visuals/icons/create/add_icon.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/visuals/icons/create/remove_icon.svg";
import { ReactComponent as XIcon } from "../../../../assets/visuals/icons/x_icon.svg";
// Mantine
import { Input, NativeSelect, TagsInput } from "@mantine/core";

const SecondStage = () => {
  //variables
  const [inputRequired, setInputRequired] = useState<string>("");
  const [inputRequiredKey, setInputRequiredKey] = useState<boolean>(true);
  const {
    createFormRef,
    currentStage,
    setCurrentStage,

    optionQuestions,
    setOptionQuestions,

    optionNoticeEnabled,
    setOptionNoticeEnabled,

    // optionPasswordEnabled,
    // setOptionPasswordEnabled,
  } = useCreatePageContext();
  // const { userProfile } = useUserProfileContext();

  // option notice
  const [textCount, setTextCount] = useState<number>(0);

  // option questions
  const [questionId, dispatchQuestionId] = useReducer((x) => ++x, 0);

  // publication url
  const [isURLAvailable, setIsURLAvailable] = useState<string>("default");
  const [publicationURLPlaceholder, setPublicationURLPlaceholder] =
    useState<string>("");

  //functions
  function checkSecondStageFilled() {
    const form = createFormRef.current;

    // option 1: notice
    if (optionNoticeEnabled === "enabled") {
      if (!form?.optionNotice.value) {
        inputRequiredButton("input-required");
        return;
      }
    }

    // option 2: question
    if (optionQuestions?.length > 0) {
      for (let x = 0; x < optionQuestions.length; ++x) {
        if (optionQuestions[x].question) {
        } else {
          inputRequiredButton("input-required");
          return;
        }
      }
    }

    // announcement address
    if (isURLAvailable === "available") {
    } else {
      inputRequiredButton("input-required url");
      return;
    }

    // result
    setCurrentStage("third");
  }

  function inputRequiredButton(errorType: string) {
    if (inputRequiredKey) {
      setInputRequiredKey(false);
      setInputRequired(errorType);

      setTimeout(() => {
        setInputRequired("");
        setInputRequiredKey(true);
      }, 2500);
    } else {
    }
  }

  function formatPublicationURL(e: any) {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-z0-9-]/g, "");
    e.target.value = filteredValue;
  }

  async function checkPublicationURL() {
    const typedURL = createFormRef.current!.publicationURL.value;

    if (typedURL !== "") {
      // check on reserved url
      const isReserved = ProductMetaData.publication_url_reserved.some(
        (reservedURL) => typedURL.startsWith(reservedURL)
      );

      if (isReserved) {
        setIsURLAvailable("not-available");
        return;
      }

      // check on server
      try {
        const response = await axios.get(
          `${WebsiteURL().serverURL()}/api/publications/check/${typedURL}`
        );
        // get the availability
        const data = response.data;
        if (data.available) {
          setIsURLAvailable("available");
        } else {
          setIsURLAvailable("not-available");
        }
      } catch (error) {
        console.error("Error checking publicationURL:", error);
        throw error;
      }
    } else {
      setIsURLAvailable("default");
    }
  }

  //useEffects
  useEffect(() => {
    // publication URL placeholder
    function eventNameToURL(eventName: string) {
      return eventName
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, "")
        .trim()
        .replace(/\s+/g, "-");
    }

    setPublicationURLPlaceholder(
      eventNameToURL(createFormRef.current?.eventName.value)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStage]);

  return (
    <div className="each-stage" id="second-stage">
      <div className="input-section">
        {/* Option Notice */}
        <div className="input-category">
          <label className="main-label" id="main-label-second">
            NOTICE NOTE
          </label>

          <div className="input-main-group">
            {/* 2-c.checkbox-switch */}
            <div className="checkbox-switch-container">
              <span>Disable</span>

              <label className="checkbox-switch">
                <input
                  type="checkbox"
                  name="noticeSwitch"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOptionNoticeEnabled("enabled");
                    } else {
                      setOptionNoticeEnabled("disabled");
                    }
                  }}
                />
                <span className="slider"></span>
              </label>

              <span>Enable</span>
            </div>

            {/* 2-1.notice-note */}
            <div
              className={`input-sub-group checkbox-sub-group ${optionNoticeEnabled}`}
            >
              <div className="input-wrapper column-wrapper">
                <label className="sub-label" htmlFor="notice-note">
                  Notice note message:
                </label>

                <div className="textarea-wrapper">
                  <textarea
                    className="textarea-box"
                    id="notice-note-textarea"
                    name="optionNotice"
                    maxLength={500}
                    onChange={(e) => {
                      setTextCount(e.target.value.length);
                    }}
                    placeholder="dress code, parking instruction, etc."
                  />
                  <span className="length-count">{textCount}/500</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Option Password */}
        <></>

        {/* start ////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Option Question */}
        <div className="input-category">
          <label className="main-label" id="main-label-second">
            Follow Up Questions ({optionQuestions.length}/2)
          </label>

          <div className="input-main-group">
            <span className="special-instruction-span">
              (Attendance question is default for all templates. Add follow up
              questions like menus or dietary restrictions.)
            </span>

            {optionQuestions.map((element, index) => {
              return (
                <div key={index} className="follow-up-questions-container">
                  <div className="follow-up-questions-wrapper">
                    <div className="input-sub-group">
                      <div className="input-wrapper row-wrapper question-wrapper">
                        <label className="sub-label">Question:</label>

                        <Input
                          className="average-input"
                          id="question-input"
                          variant="filled"
                          color="#f4f4f4"
                          radius="md"
                          placeholder={
                            element.answer_type === "Radio"
                              ? "Select your dinner menu."
                              : "Do you have any dietary restrictions?"
                          }
                          value={element.question}
                          onChange={(e) => {
                            setOptionQuestions((current) =>
                              current.map((item, childIndex) =>
                                childIndex === index
                                  ? { ...item, question: e.target.value }
                                  : item
                              )
                            );
                          }}
                        ></Input>
                      </div>
                    </div>

                    <div className="input-sub-group">
                      <div className="input-wrapper row-wrapper question-wrapper">
                        <label className="sub-label">Answer Type:</label>

                        <NativeSelect
                          id="question-native-select"
                          variant="filled"
                          radius="md"
                          name="answerType"
                          value={element.answer_type}
                          data={["Input", "Radio"]}
                          onChange={(e) => {
                            const { value } = e.target;

                            setOptionQuestions((current) =>
                              current.map((item, childIndex) =>
                                childIndex === index
                                  ? { ...item, answer_type: value }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>
                    </div>

                    {optionQuestions[index]?.answer_type === "Radio" ? (
                      <div
                        className={`input-sub-group ${
                          element?.answer_type === "Radio" ? "show" : "hide"
                        }`}
                      >
                        <div className="input-wrapper row-wrapper question-wrapper">
                          <label className="sub-label">Answer options:</label>

                          <div id="question-tags-input">
                            <TagsInput
                              name="questionOptions"
                              radius="md"
                              variant="filled"
                              value={element.answer_choices}
                              placeholder="Enter options"
                              onChange={(values) => {
                                setOptionQuestions((current) =>
                                  current.map((item, childIndex) =>
                                    childIndex === index
                                      ? { ...item, answer_choices: values }
                                      : item
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className="button question-add-remove-button"
                    id="follow-up-question-remove-button"
                    onClick={() => {
                      setOptionQuestions((currentQuestions) =>
                        currentQuestions.filter(
                          (item, childIndex) => childIndex !== index
                        )
                      );
                    }}
                  >
                    <RemoveIcon />
                    <span>remove</span>
                  </div>
                </div>
              );
            })}

            {optionQuestions.length < 2 ? (
              <div
                className="button question-add-remove-button"
                id="follow-up-question-add-button"
                onClick={() => {
                  setOptionQuestions((current) => [
                    ...current,
                    {
                      question_id: questionId,
                      question: "",
                      answer_type: "Input",
                      answer_choices: ["Beef", "Chicken"],
                    },
                  ]);

                  dispatchQuestionId();
                }}
              >
                <AddIcon />
                <span>Add</span>
              </div>
            ) : null}
          </div>
        </div>
        {/* end ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {/* URL Address */}
        <div className="input-category">
          <label className="main-label">RSVP Website Address</label>

          <div className="input-main-group">
            <div className="input-sub-group">
              <div className="input-wrapper" id="publication-url-input-wrapper">
                <label
                  className="sub-label"
                  id="publication-url-label"
                  htmlFor="publication-url"
                >
                  URL:
                </label>

                <div className="input-availability-check-wrapper">
                  <span className="input-placeholder-span">
                    https://www.pixely.ca/
                  </span>

                  <div className="input-availability-check-no-wrap">
                    <input
                      className="input-box average-input"
                      name="publicationURL"
                      id="publication-url"
                      color="#fff"
                      onChange={(e) => {
                        formatPublicationURL(e);
                        setIsURLAvailable("default");
                      }}
                      defaultValue={publicationURLPlaceholder}
                    />

                    <div className={`availability-display ${isURLAvailable}`}>
                      {isURLAvailable ? (
                        <VIcon />
                      ) : isURLAvailable === undefined ? (
                        <VIcon />
                      ) : (
                        <XIcon />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className={`button narrow availability-button ${isURLAvailable}`}
                  onClick={checkPublicationURL}
                >
                  Check availability
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**********************************************************/}
      <div className="button-section">
        <div
          className="button stage-button back-button"
          onClick={() => {
            setCurrentStage("first");
          }}
        >
          Back
        </div>

        <div
          className={`button stage-button ${inputRequired}`}
          id="second-stage-button"
          onClick={checkSecondStageFilled}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default SecondStage;
