import { useState, useEffect } from "react";
import { useCreatePageContext } from "../../../../contexts/createPageContext";

const FirstStage = () => {
  //variables
  const [inputRequired, setInputRequired] = useState<string>("");
  const [inputRequiredKey, setInputRequiredKey] = useState<boolean>(true);
  const [textCount, setTextCount] = useState<number>(0);

  const { createFormRef, setCurrentStage } = useCreatePageContext();

  const [invitationMessageState, setInvitationMessageState] =
    useState<string>("");

  const purposeAndSampleMessage = [
    {
      purpose: "celebration",
      sampleMessage:
        "You're invited to a lively celebration in honor of Mark and Lucy’s 10th Anniversary! Let’s come together to toast to love, laughter, and a decade of memories.",
    },
    { purpose: "party", sampleMessage: "You're invited to Jake's party" },
    {
      purpose: "reunion",
      sampleMessage:
        "We're thrilled to announce our upcoming family reunion! It’s a special chance for us to gather, celebrate, and enjoy each other’s company.",
    },
    {
      purpose: "funeral",
      sampleMessage:
        "We invite you to honor and remember Anna Wright at a memorial service dedicated to celebrating her life.",
    },
    {
      purpose: "wedding",
      sampleMessage:
        "🎉We are getting married!🎉 Join us in celebrating the union of Emily Parker and James Williams.",
    },
    {
      purpose: "seminar",
      sampleMessage:
        'Please join us for a seminar on "Emerging Trends in Artificial Intelligence", presented by Dr. Geoffrey Hinton.',
    },
  ];

  //functions
  async function checkFirstStageFilled() {
    const form = createFormRef.current;

    if (
      // 1.check input filled
      form?.eventName?.value.trim() &&
      form?.invitationMessage?.value.trim() &&
      form?.eventDate?.value &&
      form?.eventTime?.value &&
      form?.eventLocationAddress?.value.trim() &&
      form?.rsvpDeadline?.value
    ) {
      // 2.check deadline data error
      const eventDate = new Date(form?.eventDate?.value);
      const rsvpDeadline = new Date(form?.rsvpDeadline?.value);
      if (rsvpDeadline > eventDate) {
        inputRequiredButton("input-required deadline");
        return;
      }

      setCurrentStage("second");
    } else {
      inputRequiredButton("input-required");
    }
  }

  function inputRequiredButton(errorType: string) {
    if (inputRequiredKey) {
      setInputRequiredKey(false);
      setInputRequired(errorType);

      setTimeout(() => {
        setInputRequired("");
        setInputRequiredKey(true);
      }, 2500);
    } else {
    }
  }

  // useEffect
  useEffect(() => {
    setTextCount(invitationMessageState.length);
  }, [invitationMessageState]);

  useEffect(() => {}, []);

  return (
    <div className="each-stage" id="first-stage">
      {/* Input Section */}
      <div className="input-section">
        <div className="input-category">
          <label className="main-label" id="main-label-second">
            ABOUT EVENT
          </label>

          <div className="input-main-group">
            <div className="input-sub-group">
              <div className="input-wrapper column-wrapper">
                <label
                  className="sub-label "
                  id="main-label-second"
                  htmlFor="event-name"
                >
                  Name your event:
                </label>

                <input
                  className="input-box average-input"
                  id="event-name"
                  name="eventName"
                  placeholder="Jake's Party"
                ></input>
              </div>
            </div>

            <div className="input-sub-group">
              <div className="input-wrapper column-wrapper">
                <label
                  className="sub-label "
                  id="main-label-second"
                  htmlFor="invitation-message"
                >
                  Invitation message:
                </label>

                <div className="textarea-wrapper">
                  <textarea
                    className="textarea-box"
                    id="invitation-message"
                    name="invitationMessage"
                    maxLength={200}
                    onChange={(e) => {
                      setInvitationMessageState(e.target.value);
                      // setTextCount(e.target.value.length);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    value={invitationMessageState}
                  />
                  <span className="length-count">{textCount}/200</span>
                </div>

                <div className="sample-message-container">
                  <span className="special-instruction-span">
                    Having writer's block? Choose the purpose of the RSVP
                    website.
                  </span>

                  <div className="sample-message-button-wrapper">
                    {purposeAndSampleMessage.map((element, index) => {
                      return (
                        <span
                          key={index}
                          className={`sample-message-button ${element.purpose}`}
                          onClick={() => {
                            setInvitationMessageState(element.sampleMessage);
                          }}
                        >
                          {element.purpose}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="input-sub-group">
              <div className="input-wrapper row-wrapper">
                <label className="sub-label" htmlFor="date-of-birth">
                  Date:
                </label>

                <input
                  className="input-box date-input"
                  type="date"
                  id="date-of-birth"
                  name="eventDate"
                />
              </div>
            </div>

            <div className="input-sub-group">
              <div className="input-wrapper row-wrapper">
                <label className="sub-label" htmlFor="date-of-passing">
                  Time:
                </label>

                <input
                  className="input-box date-input"
                  type="time"
                  id="date-of-passing"
                  name="eventTime"
                />
              </div>
            </div>

            <div className="input-sub-group add-margin-bottom">
              <label className="sub-label" htmlFor="first-name">
                Location:
              </label>
            </div>

            <div className="input-sub-group input-sub-sub-group">
              <div className="input-wrapper row-wrapper">
                <label className="sub-label" htmlFor="first-name">
                  Name:
                </label>

                <input
                  className="input-box average-input"
                  id="first-name"
                  name="eventLocationName"
                  placeholder="(Optional)"
                ></input>
              </div>

              <div className="input-wrapper row-wrapper">
                <label className="sub-label" htmlFor="first-name">
                  Address:
                </label>

                <input
                  className="input-box long-input"
                  id="first-name"
                  name="eventLocationAddress"
                  placeholder="12 Street, City, PV"
                ></input>
              </div>
            </div>
          </div>
        </div>

        <div className="input-category">
          <label className="main-label" id="main-label-second">
            RSVP DEADLINE
          </label>

          <div className="input-main-group">
            <div className="input-sub-group">
              <div className="input-wrapper row-wrapper">
                <label className="sub-label" htmlFor="rsvp-deadline">
                  Deadline date:
                </label>

                <input
                  className="input-box date-input"
                  type="date"
                  id="rsvp-deadline"
                  name="rsvpDeadline"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* button section*/}
      <div className="button-section">
        <div
          className={`stage-button ${inputRequired}`}
          id="first-stage-button"
          onClick={() => {
            checkFirstStageFilled();
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default FirstStage;
