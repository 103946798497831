const WebsiteURL = () => {
  const domain = window.location.hostname;

  function clientURL() {
    if (domain.includes("www.pixely.ca")) {
      return `https://www.pixely.ca`;
    } else {
      // return `https://www.pixely.ca`;
      return `http://localhost:3000`;
    }
  }

  function serverURL() {
    if (domain.includes("www.pixely.ca")) {
      return "https://pixely-server-eafde.ondigitalocean.app";
    } else {
      // return "https://pixely-server-eafde.ondigitalocean.app";
      return "http://localhost:4000";
    }
  }

  return {
    clientURL,
    serverURL,
  };
};

export default WebsiteURL;
