import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  //variables
  const [textCount, setTextCount] = useState<number>(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  //functions
  function textareaCount() {
    setTextCount(textareaRef.current!.value.length);
  }

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_473rq2z",
        "template_fsc1bw8",
        formRef.current!,
        "q2HwJy7GyV1LkB9YV"
      )
      .then(
        (result) => {
          window.alert("Message sent successfully!");
          window.location.reload();
        },
        (error) => {
          window.alert("Error: Sorry, something went wrong.");
        }
      );
  };

  return (
    <form ref={formRef} onSubmit={sendEmail} className="contact-form">
      <div className="form-element" id="name-element">
        <div className="form-input-div">
          <input
            className="form-input"
            type="text"
            placeholder=" "
            name="name"
            required
          ></input>
        </div>
        <label className="form-label">Name*</label>
      </div>

      <div className="form-element" id="phone-element">
        <div className="form-input-div">
          <input
            className="form-input"
            type="text"
            placeholder=" "
            name="phone_number"
          ></input>
        </div>
        <label className="form-label">Phone Number</label>
      </div>

      <div className="form-element" id="email-element">
        <div className="form-input-div">
          <input
            className="form-input"
            type="email"
            placeholder=" "
            name="email"
            required
          ></input>
        </div>
        <label className="form-label">Email*</label>
      </div>

      <div className="form-element" id="subject-element">
        <div className="form-input-div">
          <input
            className="form-input"
            type="text"
            placeholder=" "
            name="subject"
          ></input>
        </div>
        <label className="form-label">Subject</label>
      </div>

      <div className="form-element" id="reason-element">
        <div className="form-input-div">
          <select className="form-input" name="reason" defaultValue="" required>
            <option value="" disabled hidden></option>
            <option value="Edit">Edit/Delete</option>
            <option value="Inquiry">Inquiry</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <label className="form-label">Reason*</label>
      </div>

      <div className="form-element" id="textarea-element">
        <label className="form-label" id="textarea-label">
          Message*
        </label>
        <div className="form-input-div" id="textarea-input-div">
          <textarea
            className="form-input"
            id="textarea-input"
            placeholder="Any questions, feedback, or requests are welcome."
            maxLength={500}
            name="message"
            ref={textareaRef}
            onChange={textareaCount}
            required
          ></textarea>
          <div id="remaining-number">{textCount}/500</div>
        </div>
      </div>

      <div className="button" id="submit-button">
        <input type="submit" value="Send Message"></input>
      </div>
    </form>
  );
};

export default ContactForm;
