import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// data
import WebsiteURL from "../../../assets/database/WebsiteURL";
import SystemData from "../../../assets/database/general_system_data.json";
// icons
// import { ReactComponent as FilterIcon } from "../../../assets/visuals/icons/product-listing/filter_icon.svg";
// import { ReactComponent as CheckBoxBlankIcon } from "../../../assets/visuals/icons/product-listing/check_box_blank_icon.svg";
// import { ReactComponent as CheckBoxFilledIcon } from "../../../assets/visuals/icons/product-listing/check_box_filled_icon.svg";
// functions
// import { formatDate } from "../../../functions/Functions";
// Mantine
import { NativeSelect } from "@mantine/core";
// types
import { TemplateType } from "../../../types/Types";

// type TemplateType = {
//   template_id: string;
//   template_name: string;

//   pricing_level: number;
//   template_purposes: string[];
//   released_date: string;

//   product_url: string;
//   preview_url: string;

//   thumbnail: string;
// };

const ProductListing = () => {
  // variables
  const [templateList, setTemplateList] = useState<TemplateType[]>([]);
  const [sortValue, setSortValue] = useState("Oldest");

  // functions
  const sortTemplates = (templates: TemplateType[], sortOption: string) => {
    let sortedList = [...templates];

    switch (sortOption) {
      case "Latest Release":
        sortedList = sortedList.sort((a, b) => {
          if (a.released_date > b.released_date) {
            return -1;
          } else {
            return 1;
          }
        });
        break;
      case "Most Popular":
        sortedList = sortedList.sort((a, b) => {
          return b.popularity_index - a.popularity_index;
        });
        break;
      case "Name A-Z":
        sortedList = sortedList.sort((a, b) => {
          return a.template_name.localeCompare(b.template_name);
        });
        break;
      case "Oldest":
        sortedList = sortedList.sort((a, b) => {
          if (a.released_date > b.released_date) {
            return 1;
          } else {
            return -1;
          }
        });
        break;
      default:
        return templates;
    }

    setTemplateList(sortedList);
  };

  // useEffect
  useEffect(() => {
    const getTemplateList = async () => {
      try {
        const response = await axios.get(
          `${WebsiteURL().serverURL()}/api/templates`,
          {
            withCredentials: true,
          }
        );

        setTemplateList(response.data);
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 404) {
            // No matching data
            console.error("No data found.");
          } else {
            // Other server error
            console.error("Server error:", error);
          }
        } else {
          // Network error or similar
          console.error("Network error:", error);
        }
      }
    };

    getTemplateList();
  }, []);

  useEffect(() => {
    sortTemplates(templateList, sortValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (e: any) => {
      // Get the currently hovered element
      const hoveredElement = document.querySelector(".mobile-hovered");

      // Check if the clicked target is not the hovered element or inside it
      if (hoveredElement && !hoveredElement.contains(e.target)) {
        hoveredElement.classList.remove("mobile-hovered");
      }
    };

    // Add event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <main className="main-page" id="product-listing-page">
      <div className="breadcrumb-sorter-wrapper">
        {/* breadcrumb */}
        <div className="breadcrumb">
          <Link to="/">Home</Link>
          {` > `}
          <Link to={`${SystemData.navigation_url[1]}`}>templates</Link>
        </div>

        {/* sorter */}
        <div className="sorter">
          <label>Sort by: </label>

          <NativeSelect
            value={sortValue}
            size="xs"
            onChange={(event) => setSortValue(event.currentTarget.value)}
            data={["Latest Release", "Most Popular", "Name A-Z", "Oldest"]}
          />
        </div>
      </div>

      {/* <div className="filter-display-wrapper"> */}
      {/* filter */}
      <div className="filter">
        <div className="filter-background">
          {/* <span className="filter-heading-wrapper">
            <FilterIcon />
            <span>Filter</span>
          </span> */}

          {/* filter - color */}
          {/* <div className="filter-element">
            <label className="filter-element-heading">Color:</label>

            <div className="filter-options-wrapper color">
              <div className="option-wrapper">
                <input
                  name="color_dark"
                  type="checkbox"
                  id="color-dark"
                  value="color-dark"
                ></input>
                <label htmlFor="color-dark">
                  <span>Dark</span>
                </label>
              </div>

              <div className="option-wrapper">
                <input
                  name="color_blue"
                  type="checkbox"
                  id="color-blue"
                  value="color-blue"
                ></input>
                <label htmlFor="color-blue">
                  <span>Blue</span>
                </label>
              </div>

              <div className="option-wrapper">
                <input
                  name="color_red"
                  type="checkbox"
                  id="color-red"
                  value="color-red"
                ></input>
                <label htmlFor="color-red">
                  <span>Red</span>
                </label>
              </div>

              <div className="option-wrapper">
                <input
                  name="color_white"
                  type="checkbox"
                  id="color-white"
                  value="color-white"
                ></input>
                <label htmlFor="color-white">
                  <span>White</span>
                </label>
              </div>
            </div>
          </div> */}

          {/* filter - price */}
          {/* <div className="filter-element">
            <label className="filter-element-heading">Pricing Level:</label>

            <div className="filter-options-wrapper pricing">
              <div className="option-wrapper">
                <input
                  name="pricing_zero"
                  type="checkbox"
                  id="pricing-zero"
                  value="pricing-zero"
                ></input>
                <label htmlFor="pricing-zero">
                  <span>Level 0</span>
                </label>
              </div>

              <div className="option-wrapper">
                <input
                  name="pricing_one"
                  type="checkbox"
                  id="pricing-one"
                  value="pricing-one"
                ></input>
                <label htmlFor="pricing-one">
                  <span>Level 1</span>
                </label>
              </div>

              <div className="option-wrapper">
                <input
                  name="pricing_two"
                  type="checkbox"
                  id="pricing-two"
                  value="pricing-two"
                ></input>
                <label htmlFor="pricing-two">
                  <span>Level 2</span>
                </label>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* display */}
      <div className="display-section">
        {templateList?.length === 0 ? (
          <>
            {[...Array(SystemData.number_of_live_templates)].map(
              (value, index) => {
                return (
                  <div key={index} className="display-anchor">
                    <div className="template-thumbnail-wrapper loading">
                      <div className="template-thumbnail loading"></div>
                    </div>
                  </div>
                );
              }
            )}
          </>
        ) : (
          <>
            {templateList.map((templateData, index) => {
              return (
                <div key={index} className="display-anchor">
                  <div
                    className="template-thumbnail-wrapper"
                    onClick={(e) => {
                      const previousElement =
                        document.querySelector(".mobile-hovered");
                      if (
                        previousElement &&
                        previousElement !== e.currentTarget
                      ) {
                        previousElement.classList.remove("mobile-hovered");
                      }

                      e.currentTarget.classList.add("mobile-hovered");
                    }}
                    onKeyUp={(e) => {
                      const previousElement = document.querySelector(
                        ".mobile-hovered-after"
                      );
                      if (
                        previousElement &&
                        previousElement !== e.currentTarget
                      ) {
                        previousElement.classList.remove("mobile-hovered");
                      }

                      e.currentTarget.classList.add("mobile-hovered");
                    }}
                  >
                    <img
                      className="template-thumbnail"
                      src={templateData.thumbnail}
                      alt="template-thumbnail"
                    />

                    <div className="template-hover-wrapper">
                      <span className="template-hover-name">
                        {templateData.template_name}
                      </span>

                      <Link
                        to={`./${templateData.product_url}/create`}
                        className="template-hover-cta-button filled"
                      >
                        Create RSVP
                      </Link>

                      <a
                        // use <a> for <title> rerender
                        href={`${WebsiteURL().clientURL()}/${
                          templateData.preview_url
                        }`}
                        className="template-hover-cta-button not-filled"
                      >
                        See Preview
                      </a>
                    </div>
                  </div>

                  <span className="template-name">
                    {templateData.template_name}
                  </span>

                  <div className="template-usage-container">
                    {templateData.template_purposes.map((purpose, index) => {
                      return (
                        <span
                          key={index}
                          className={`template-usage-span ${purpose}`}
                        >
                          {purpose}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </main>
  );
};

export default ProductListing;
