import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCreatePageContext } from "../../../../contexts/createPageContext";
import WebsiteURL from "../../../../assets/database/WebsiteURL";
// contexts
import { useUserProfileContext } from "../../../../contexts/userProfileContext";
// functions
import { formatDate, formatTime } from "../../../../functions/Functions";
// visuals
import { ReactComponent as LoadingCircle } from "../../../../assets/visuals/images/loading_circle.svg";

const ThirdStage = () => {
  // variables
  const navigate = useNavigate();
  const {
    createFormRef,

    setCurrentStage,

    selectedTemplateId, // for POST

    selectedTemplateName, // for ThirdStage

    optionQuestions,

    optionNoticeEnabled,
  } = useCreatePageContext();
  const { setUserProfile } = useUserProfileContext();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  // functions
  function gtagSendEvent() {
    (window as any).gtagSendEvent?.();
  }

  function capitalizeFirstLetter(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1).trimEnd();
  }

  const getUserDataAndCreate = async () => {
    try {
      const { data } = await axios.get(
        `${WebsiteURL().serverURL()}/auth/profile`,
        {
          withCredentials: true,
        }
      );

      if (data.user) {
        setUserProfile(data.user);
        submitForm(data.user.user_id);
      } else {
        setIsCreating(false);
        window.open(`${WebsiteURL().clientURL()}/main/login`, "_blank");
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          console.error("No data found.");
        } else {
          console.error("Server error:", error);
        }
      } else {
        console.error("Network error:", error);
      }
    }
  };

  async function submitForm(user_id: string) {
    const submittingData = {
      // Metadata
      user_id: user_id || "Not logged in",
      publication_url: createFormRef.current?.publicationURL?.value,
      template_id: selectedTemplateId,

      // Event info
      event_name: createFormRef.current?.eventName?.value
        ? createFormRef.current?.eventName?.value
        : createFormRef.current?.publicationURL?.value,
      invitation_message: capitalizeFirstLetter(
        createFormRef.current?.invitationMessage?.value
      ),
      event_date: capitalizeFirstLetter(
        createFormRef.current?.eventDate?.value
      ),
      event_time: createFormRef.current?.eventTime?.value,
      event_location_name: createFormRef.current?.eventLocationName?.value,
      event_location_address:
        createFormRef.current?.eventLocationAddress?.value,
      rsvp_deadline: createFormRef.current?.rsvpDeadline?.value,

      // Options
      ...(optionNoticeEnabled && {
        option_notice: createFormRef.current?.optionNotice?.value,
      }),
      ...(optionQuestions.length > 0 && {
        option_questions: optionQuestions,
      }),
    };

    // trigger POST commend
    try {
      const response = await axios.post(
        `${WebsiteURL().serverURL()}/api/publications`,
        submittingData
      );

      // if successful get publication's "_id"
      const publicationId = response.data.publication_id;
      navigate(`/main/confirmation/${publicationId}`);
    } catch (error) {
      setIsCreating(false);
      alert("Sorry, something went wrong on our end. Please try again later.");
      console.error(error);
    } finally {
      setIsCreating(false);
    }
  } //end of submitForm

  async function handleFormSubmitClick() {
    setIsCreating(true);

    getUserDataAndCreate();
  } //end of handleFormSubmit

  // useEffects

  return (
    <div className="each-stage" id="third-stage">
      <div className="input-section">
        <div className="input-category">
          <label className="main-label">EVENT INFO</label>

          <div className="input-main-group confirm-main-group">
            <div className="confirm-wrapper">
              <label className="sub-label">Event name:</label>

              <span className="confirm-value">
                {createFormRef.current?.eventName?.value === "" || undefined
                  ? createFormRef.current?.publicationURL?.value
                  : createFormRef.current?.eventName?.value}
              </span>
            </div>

            <div className="confirm-wrapper">
              <label className="sub-label">Invitation Message:</label>

              <span className="confirm-value">
                {createFormRef.current?.invitationMessage?.value}
              </span>
            </div>

            <div className="confirm-wrapper">
              <label className="sub-label">Event date:</label>

              <span className="confirm-value">
                {formatDate(createFormRef.current?.eventDate?.value)}
              </span>
            </div>

            <div className="confirm-wrapper">
              <label className="sub-label">Event time:</label>

              <span className="confirm-value">
                {formatTime(createFormRef.current?.eventTime?.value)}
              </span>
            </div>

            <div className="confirm-wrapper">
              <label className="sub-label">Event location:</label>

              {createFormRef.current?.eventLocationName?.value ? (
                <div className="confirm-wrapper">
                  <label className="sub-label">Name:</label>

                  <span className="confirm-value">
                    {createFormRef.current?.eventLocationName?.value}
                  </span>
                </div>
              ) : null}

              <div className="confirm-wrapper">
                <label className="sub-label">Address:</label>

                <span className="confirm-value">
                  {createFormRef.current?.eventLocationAddress?.value}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="input-category">
          <label className="main-label">OPTIONS</label>

          <div className="input-main-group confirm-main-group">
            {/* Notice Note */}
            {optionNoticeEnabled === "enabled" ? (
              <div className="confirm-wrapper">
                <label className="sub-label">Notice note:&nbsp;</label>

                <span className="confirm-value">
                  {createFormRef.current?.optionNotice?.value}
                </span>
              </div>
            ) : null}

            {/* Follow Up Questions */}
            {optionQuestions?.map((questionData, index) => (
              <div className="confirm-wrapper">
                <label className="sub-label">
                  Follow-up question {index + 1}:&nbsp;
                </label>

                <div
                  key={index}
                  className="confirm-wrapper follow-up-question-wrapper"
                >
                  <label className="confirm-question">
                    "{questionData.question}"&nbsp;
                  </label>

                  <div className="confirm-answer-type-container">
                    {questionData.answer_type === "Input" ? (
                      <input className="confirm-answer-input" disabled></input>
                    ) : (
                      <div className="confirm-answer-radio">
                        {questionData.answer_choices?.map((choice, index) => {
                          return (
                            <div className="confirm-radio-wrapper">
                              <input
                                key={index}
                                className="confirm-radio-input"
                                type="radio"
                                disabled
                                value={choice}
                              ></input>

                              <label className="confirm-radio-label">
                                {choice}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="input-category">
          <label className="main-label">RSVP INFO</label>

          <div className="input-main-group confirm-main-group">
            <div className="confirm-wrapper">
              <label className="sub-label">Template selected:</label>

              <span className="confirm-value">{selectedTemplateName}</span>
            </div>

            <div className="confirm-wrapper">
              <label className="sub-label">Website address (URL):</label>

              <span className="confirm-value confirm-publication-url">
                https://www.pixely.ca/
                {createFormRef.current?.publicationURL?.value}
              </span>
            </div>

            <div className="confirm-wrapper">
              <label className="sub-label">RSVP deadline:</label>

              <span className="confirm-value">
                By {formatDate(createFormRef.current?.rsvpDeadline?.value)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/**********************************************************/}

      <div className="button-section">
        <div
          className={`stage-button back-button`}
          id="third-stage-button"
          onClick={() => {
            setCurrentStage("second");
          }}
        >
          Back
        </div>

        <div
          className={`stage-button ${isCreating}`}
          id="complete-button"
          onClick={() => {
            handleFormSubmitClick();
            gtagSendEvent();
          }}
        >
          <span>Complete</span>

          <LoadingCircle />

          {/* <svg className="loader-svg" viewBox="25 25 50 50">
            <circle
              className="loader-circle"
              cx="50"
              cy="50"
              r="20"
              fill="none"
            />
          </svg> */}
        </div>
      </div>
    </div>
  );
};

export default ThirdStage;
