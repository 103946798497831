import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
// screenshots
import Feature1 from "../../../assets/visuals/images/feature_1.webp";
import Feature2 from "../../../assets/visuals/images/feature_2.webp";
import Feature3 from "../../../assets/visuals/images/feature_3.webp";
import IntroductionImage from "../../../assets/visuals/images/home_introduction_img.webp";
// data
import SystemData from "../../../assets/database/general_system_data.json";
// gsap
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  // gsap
  const gsapRefs = useRef<Array<any>>([]);
  useEffect(() => {
    gsapRefs.current.forEach((gsapRef) => {
      gsap.fromTo(
        gsapRef,
        { y: 30, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.6,
          ease: "ease",
          scrollTrigger: {
            trigger: gsapRef,
            start: "top 90%",
            toggleActions: "play none none none",
          },
        }
      );
    });
  }, [gsapRefs]);

  return (
    <main className="main-page" id="home-page">
      {/* hero section */}
      <section className="average-section" id="hero-section">
        <h1 className="section-heading hero-heading">
          RSVP Website for Everyday Occasions
        </h1>

        <p className="home-p hero-p">
          Simply enter your event details, and we'll handle the rest—no
          customization needed. Get your site up and running effortlessly, and
          focus on your event.
        </p>

        <Link
          className="button"
          id="home-cta-button"
          to={`${SystemData.navigation_url[1]}`}
        >
          <span>Create RSVP Website</span>
        </Link>
      </section>

      {/* introduction section */}
      <section className="average-section" id="introduction-section">
        <div className="introduction-image-container">
          <img
            className="introduction-img"
            src={IntroductionImage}
            alt="small-gathering"
          ></img>
        </div>

        <div className="introduction-text-container">
          <h2 className="section-heading introduction-heading">
            Elevate Your Event Experience
          </h2>

          <p className="home-p introduction-p">
            Gatherings are a cherished part of life, and Pixely is here to help
            you make them even more memorable. Our free RSVP websites simplify
            the process with a clean, user-friendly design.
          </p>
        </div>
      </section>

      {/* feature section */}
      <section className="average-section" id="feature-section">
        {/* <h2 className="section-heading feature-heading">Website Features</h2> */}

        <div className="feature-element-container">
          <div className="feature-element">
            <img
              className="feature-img"
              src={Feature1}
              alt="create-page-first-stage"
              draggable="false"
            ></img>

            <div className="feature-text-wrapper">
              <h3 className="feature-sub-heading">Simplified Setup</h3>

              <p className="feature-p">
                Create your event effortlessly with our simple setup process.
                Choose a template, enter details, and you're ready to go.
              </p>
            </div>
          </div>

          <div className="feature-element">
            <img
              className="feature-img"
              src={Feature2}
              alt="create-page-second-stage"
              draggable="false"
            ></img>

            <div className="feature-text-wrapper">
              <h3 className="feature-sub-heading">
                Customizable Follow-Up Questions
              </h3>

              <p className="feature-p">
                Enhance guest interactions with up to two personalized follow-up
                questions, in addition to the standard attendance question.
              </p>
            </div>
          </div>

          <div className="feature-element">
            <img
              className="feature-img"
              src={Feature3}
              alt="dashboard-preview"
              draggable="false"
            ></img>

            <div className="feature-text-wrapper">
              <h3 className="feature-sub-heading">Intuitive Dashboard</h3>

              <p className="feature-p">
                Easily manage and track your event with our user-friendly
                dashboard. Access real-time analytics and insights, giving you
                full control and visibility over guest responses and event
                planning.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* instruction section */}
      <section className="average-section" id="instruction-section">
        <h2 className="section-heading">How It Works</h2>

        <div className="instruction-element-container">
          <div className="instruction-element first">
            <span className="instruction-numbering">1</span>

            <div className="instruction-element-text-wrapper">
              <h4 className="instruction-heading">
                Choose the Template That Best Fits Your Event
              </h4>
              <p className="instruction-body">
                Select from our range of carefully designed templates tailored
                to suit any occasion. Simply pick the one that aligns with your
                event's style and you're ready to move forward.
              </p>
            </div>
          </div>

          <div className="instruction-element second">
            <span className="instruction-numbering">2</span>

            <div className="instruction-element-text-wrapper">
              <h4 className="instruction-heading">
                Enter the Event Information
              </h4>
              <p className="instruction-body">
                Fill in your event details quickly and easily. Our streamlined
                process ensures you only need to focus on the essential
                information, making setup a breeze.
              </p>
            </div>
          </div>

          <div className="instruction-element third">
            <span className="instruction-numbering">3</span>

            <div className="instruction-element-text-wrapper">
              <h4 className="instruction-heading">Send the Invitations</h4>
              <p className="instruction-body">
                Share your event with guests via email, text, or a simple QR
                code. With just a few clicks, your invitations are sent, and
                your guests can start responding immediately.
              </p>
            </div>
          </div>

          <div className="instruction-element fourth">
            <span className="instruction-numbering">4</span>

            <div className="instruction-element-text-wrapper">
              <h4 className="instruction-heading">
                Get Live Data and Prepare for the Event
              </h4>
              <p className="instruction-body">
                Monitor real-time responses through our intuitive dashboard.
                Access live data to help you stay organized and ready for your
                event.
              </p>
            </div>
          </div>
        </div>

        <Link to="/main/templates" className="button" id="instruction-button">
          <span>Browse Templates</span>
        </Link>
      </section>

      {/* policy section */}
      {/* <section className="wide-section" id="policy-section">
        <div className="policy-block">
          <h2 className="section-heading policy-heading">
            Know your Pixely rights
          </h2>

          <p className="home-p policy-p">
            Your privacy is our priority. Any information you use to create your
            RSVP website remain entirely your property. Pixely will not use,
            share, or distribute your data in any way.
          </p>

          <Link
            className="button"
            id="home-legal-button"
            to="/main/privacy-policy"
          >
            <span>Learn more</span>
          </Link>
        </div>
      </section> */}
    </main>
  );
};

export default Home;
