import { useEffect, useState, useRef } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Link,
} from "react-router-dom";
import axios from "axios";
import ServerURL from "../../assets/database/WebsiteURL";
// database
import SystemData from "../../assets/database/general_system_data.json";
// context
import { CreatePageContextProvider } from "../../contexts/createPageContext";
import { useUserProfileContext } from "../../contexts/userProfileContext";
// components
import Header from "../layouts/Header/Header";
import CreateHeader from "../layouts/CreateHeader/CreateHeader";
import DashboardHeader from "../layouts/DashboardHeader/DashboardHeader";
import Footer from "../layouts/Footer/Footer";
import DashboardFooter from "../layouts/DashboardFooter/DashboardFooter";

import Home from "../main-pages/Home/Home";
import Contact from "../main-pages/Contact/Contact";
import Login from "../user-pages/Login/Login";
import NotFound from "../main-pages/NotFound/NotFound";

import ProductListing from "../product-pages/ProductListing/ProductListing";
import ProductDetail from "../product-pages/ProductDetail/ProductDetail";

import PrivacyPolicy from "../legal-pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../legal-pages/TermsConditions/TermsConditions";

import Dashboard from "../user-pages/Dashboard/Dashboard";
import RsvpDashboard from "../user-pages/RsvpDashboard/RsvpDashboard";

import CreateProcess from "../create-pages/CreateProcess/CreateProcess";
import CreateConfirmation from "../create-pages/CreateConfirmation/CreateConfirmation";

import RSVP from "../RSVP/RSVP";

function App() {
  //variables
  const { setUserProfile, appRerender } = useUserProfileContext();

  //functions
  // function grantCookieConsent() {
  //   (window as any).allConsentGranted?.();
  // }

  //useEffect

  //useEffect
  useEffect(() => {
    const getUserData = async () => {
      try {
        const { data } = await axios.get(
          `${ServerURL().serverURL()}/auth/profile`,
          {
            withCredentials: true,
          }
        );

        setUserProfile(data.user);
      } catch (error: any) {
        console.error("");

        // if (error.response) {
        //   if (error.response.status === 404) {
        //     console.error("No data found.");
        //   } else {
        //     console.error("Server error:", error);
        //   }
        // } else {
        //   console.error("Network error:", error);
        // }
      }
    };

    getUserData();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRerender]);

  return (
    <div className="app">
      <Router>
        <ScrollToTop />

        <Routes>
          {/***** No Header or Footer *****/}
          <Route path="/:publication_url" element={<RSVP />} />

          {/***** Header & Footer *****/}
          <Route
            element={
              <>
                <Header />
                <Outlet />
                <Footer />
              </>
            }
          >
            <Route path={`/`} element={<Home />} />
            <Route path={`/main/contact`} element={<Contact />} />

            {/* product pages */}
            <Route
              path={`${SystemData.navigation_url[1]}`}
              element={<ProductListing />}
            />
            <Route
              path={`${SystemData.navigation_url[1]}/:product_url`}
              element={<ProductDetail />}
            />

            {/* confirmation page */}
            <Route
              path="/main/confirmation/:publication_id"
              element={<CreateConfirmation />}
            />

            <Route path="/main/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/main/terms-and-conditions"
              element={<TermsConditions />}
            />

            <Route path="*" element={<NotFound />} />
          </Route>

          {/***** Header *****/}
          <Route
            element={
              <>
                <Header />
                <Outlet />
              </>
            }
          >
            <Route path="/main/login" element={<Login />} />
          </Route>

          {/***** CreateHeader *****/}
          <Route
            element={
              <>
                <CreateHeader />
                <Outlet />
              </>
            }
          >
            <Route
              path={`${SystemData.navigation_url[1]}/:product_url/create`}
              element={
                <CreatePageContextProvider>
                  <CreateProcess />
                </CreatePageContextProvider>
              }
            />
          </Route>

          {/***** DashboardHeader *****/}
          <Route
            element={
              <>
                <DashboardHeader />
                <Outlet />
                <DashboardFooter />
              </>
            }
          >
            <Route path="/main/dashboard" element={<Dashboard />} />
            <Route
              path="/main/dashboard/:publication_url"
              element={<RsvpDashboard />}
            />
          </Route>
        </Routes>

        {/* cookie consent banner */}
        {/* <div id="cookie-consent-banner">
          <div className="text-wrapper">
            <span>This website uses cookies.</span>{" "}
            <Link to="/main/privacy-policy">Learn more</Link>
          </div>

          <div
            className="div-button"
            id="cookie-consent-button"
            onClick={() => {
              grantCookieConsent();
            }}
          >
            <span>I understand</span>
          </div>
        </div> */}
      </Router>
    </div>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
