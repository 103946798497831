import { Link, useNavigate } from "react-router-dom";
// visuals
import { ReactComponent as FullLogoWhiteWhite } from "../../../assets/visuals/pixely-logos/full_logo_white_white.svg";
import { ReactComponent as CloseIcon } from "../../../assets/visuals/icons/x_icon.svg";
// data
import SystemData from "../../../assets/database/general_system_data.json";

const CreateHeader = () => {
  return (
    <header id="create-header">
      <div className="header-logo">
        <Link to="/">
          <FullLogoWhiteWhite />
        </Link>
      </div>

      <div className="header-left">
        <Link to={`${SystemData.navigation_url[1]}`} className="account-button">
          <CloseIcon />
        </Link>
      </div>
    </header>
  );
};

export default CreateHeader;
